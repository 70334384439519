import React from "react";
import { Link } from "react-router-dom";
import logo from '../assets/logo.png'
import { changePassword } from "../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { setLoading } from "../redux/actions/loading";
import { BiLoaderAlt } from "react-icons/bi";
import PasswordInput from "../components/PasswordInput";
import parse from 'html-react-parser';

const ChangePassword = () => {
    const { mobile } = useParams();
    const [password, setPassword] = React.useState('');
    const [code, setCode] = React.useState('');
    const loading = useSelector(state => state.login.loading)
    const passwordChanged = useSelector(state => state.login.passwordChanged)
    const dispatch = useDispatch();
    const history = useHistory();
    const errors = useSelector(state => state.login.forgetErrors);
    const text = useSelector(state => state.terms?.terms?.setting?.login_page)


    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(setLoading())
        dispatch(changePassword({ mobile, password, verification_code: code }))
    }

    if (passwordChanged) {
        history.push('/')
    }
    return (
        <div>
            <div className='flex-center login-logo-wrapper'>
                <img className="logo-limits" alt='logo' src={logo} />
            </div>

            <div className='container'>
                <div className=' flex-center'>
                    <form onSubmit={handleSubmit}>
                        <h4 className='text-center login-word mb-4'>تغير كلمة المرور</h4>
                        <p className='text-color mb-4 bold'>برجاء إدخال الرمز المرسل إليكم على الهاتف وتغيير كلمة السر</p>

                        <label>رقم الهاتف</label>
                        <input defaultValue={mobile} disabled className='form-control app-input' placeholder='رقم الهاتف' />

                        <label>الكود</label>
                        <input required onChange={(e) => setCode(e.target.value)} className='form-control app-input' placeholder='الكود' />
                        <p className="register-error">{errors?.verification_code ? 'الكود غير صحيح' : null}</p>

                        <label>كلمة المرور الجديدة</label>
                        <PasswordInput onChange={(e) => setPassword(e.target.value)} placeholder='كلمة المرور الجديدة' />


                        <button style={{ width: '70px' }} className='login-button'>{
                            loading ? <BiLoaderAlt className="login-loader" /> : 'تأكيد'
                        }</button>
                        <p className='mt-2 text-color'>إذا كان لديك حساب معنا ، يرجى تسجيل الدخول.</p>
                        <Link style={{ textDecoration: 'none' }} to='/'>
                            <p className='forget-password'>تسجيل الدخول</p>
                        </Link>
                    </form>
                </div>

                {/* <div className='col-sm-8 how-to-create'>
                    <h4 className='text-center login-word mb-4'>إنشاء حساب جديد</h4>
                    <p className='mb-1'>1- هام جداً برجاء الدخول للموقع عن طريق كمبيوتر شخصي حتى يتم عرض بيانات الموقع بشكل كامل. لأن الدخول عن طريق الموبيل قد يؤدى الى أختفاء بعض البيانات الهامة </p>
                    <p className='mb-1'>2- للتسجيل اول مرة يتم الضغط على إنشاء حساب جديد ثم إتبع الخطوات .</p>
                    <p className='mb-1'>3- ستصلك SMS بقبول طلب التسجيل الخاص بك و إمكانية الدخول على الموقع و أذا لم تصلك الرساله ( لأى سبب ) سيتم الموافقة على الحساب تلقائياً بعد 24 ساعة  .</p>
                    <p className='mb-4'>4- بعد إستلام رسالة الموافقة أو بعد 24 ساعة من التسجيل يتم الدخول برقم الموبيل و كلمة المرور من تسجيل الدخول الظاهر على يمين الشاشة الرئيسية ثم إتبع الخطوات لأستكمال بياناتك .</p>
                    <Link to='/register'>
                        <button className='login-button'>إنشاء حساب جديد</button>
                    </Link>
                </div> */}

                <div className='mt-3'>
                    {
                        text?.length > 3 ?
                            parse(text) : null
                    }
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;