import { FILTER } from "../actions/types";

const initialState = {
  currentPage: 1,
  currentEstablishment: 0,
  currentLevel: 0,
  currentType: 0,
  currentId: 14,
  loading: true,
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        currentEstablishment: action.payload.currentEstablishment,
        currentLevel: action.payload.currentLevel,
        currentType: action.payload.currentType,
        currentId: action.payload.currentId,
        loading: false,
      };
    default:
      return state;
  }
};

export default filterReducer;
