import axios from 'axios';
import { myToast, myError } from '../../components/Toast';
import * as types from './types';
const lang = localStorage.getItem('i18nextLng')

export const getCats = () => dispatch => {
     axios.get(`https://bluepointwear.com/backend/ar/api/home`, {
          headers: {
               'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
     })
          .then(res => {
               dispatch({
                    type: types.CAT_LOADING,
                    payload: res.data
               })
               dispatch({
                    type: types.GET_CAT,
                    payload: res.data
               })
          })
}

export const getCatById = (id) => dispatch => {
     axios.get(`https://matjrna.ml/api/v1/category/${id}`)
          .then(res => {
               dispatch({
                    type: types.GET_CATEGORY_BY_ID,
                    payload: res.data
               })
          })
          .catch(error => myError(error?.response?.message))
}

export const addCat = (nameEn, nameAr, type, image) => dispatch => {
     const inputsData = new FormData();
     inputsData.append('nameEn', nameEn)
     inputsData.append('nameAr', nameAr)
     inputsData.append('type', type)
     inputsData.append('image', image)
     axios.post(`https://matjrna.ml/api/v1/category`, inputsData)
          .then(res => {
               dispatch({
                    type: types.ADD_CAT,
                    payload: res
               })
               myToast(lang === 'ar' ? 'تم إضافة قسم جديد' : 'New category has been added')
          })
          .catch(error => myError(error?.response?.message))
}

export const editCat = (id, nameEn, nameAr, type, image, oldImage) => dispatch => {
     const inputsData = new FormData();
     inputsData.append('nameEn', nameEn)
     inputsData.append('nameAr', nameAr)
     inputsData.append('type', type)
     inputsData.append('image', image)
     inputsData.append('old_image', oldImage)
     axios.patch(`https://matjrna.ml/api/v1/category/${id}`, inputsData)
          .then(res => {
               dispatch({
                    type: types.ADD_CAT,
                    payload: res
               })
               myToast(lang === 'ar' ? 'تم تعديل القسم' : 'Category has been edited')
          })
          .catch(error => myError(error?.response?.message))
}

export const deleteCat = (id) => dispatch => {
     axios.delete(`https://matjrna.ml/api/v1/category/${id}`)
          .then(res => {
               dispatch({
                    type: types.ADD_CAT,
                    payload: res
               })
               myToast(lang === 'ar' ? 'تم حذف القسم' : 'Category has been deleted')
          })
          .catch(error => myError(error?.response?.message))
}