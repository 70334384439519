import React from 'react';
import ProgressBar from '../components/ProgressBar';

const Checkout = () => {
    React.useState(() => {
        document.title = 'Blue Point | مراجعة الحساب'
    }, [])
    return (
        <div>
            
            <ProgressBar />

        </div>
    )
}

export default Checkout;