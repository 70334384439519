import { GET_TERMS, ADD_TERM, TERMS_LOADING, GET_SUPPORT, SUPPORT_LOADING, LOADING } from '../actions/types'

const initialState = {
    support: [],
    term: [],
    updated: [],
    loading: true
}

const termsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUPPORT:
            return {
                ...state,
                support: action.payload,
                loading: false
            };
        case GET_TERMS:
            return {
                ...state,
                terms: action.payload,
                loading: false
            };
        case ADD_TERM:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case SUPPORT_LOADING:
            return {
                loading: true
            };
        case TERMS_LOADING:
            return {
                loading: true
            };
        case LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default termsReducer;