import { GET_ORDERS, GET_ORDER_BY_ID, UPDATE_ORDER, ORDERS_LOADING, LOADING, GET_ALL_ORDERS } from '../actions/types'

const initialState = {
    orders: [],
    order: [],
    allOrders: [],
    updated: [],
    loading: true
}

const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDERS:
            return {
                ...state,
                orders: action.payload,
                loading: false
            };
        case GET_ALL_ORDERS:
            return {
                ...state,
                allOrders: action.payload,
                loading: false
            };
        case GET_ORDER_BY_ID:
            return {
                ...state,
                order: action.payload?.data?.order,
                loading: false
            };
        case UPDATE_ORDER:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case ORDERS_LOADING:
            return {
                loading: true
            };
        case LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default ordersReducer;