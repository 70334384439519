import React from 'react'
import {Modal} from 'react-bootstrap'

function FullScreenModal({show, setShow, body, modalTitle}) {
    

    return (
        <>
            <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
                <div className='flex-between p-2'>
                    <h4>{modalTitle}</h4>
                    <p onClick={() => setShow(false)} className='times full-times'>×</p>
                </div>
                <Modal.Body>{body}</Modal.Body>
            </Modal>
        </>
    );
}

export default FullScreenModal