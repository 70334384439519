import React from "react";
import { useSelector } from "react-redux";

const FifthStep = ({ setPaymentMethod, error, totalPrice, setDescription, user, reservation, descError }) => {
  const payment = useSelector((state) => state.checkout?.checkout?.payments);
  const [paymentId, setPaymentId] = React.useState(0);
  React.useEffect(() => {
    setPaymentMethod(paymentId);
  }, [paymentId, setPaymentMethod]);
  const wallet = useSelector((state) => state.users.user?.customer_wallet);
  const paymentMethods = reservation ? payment?.filter((item) => item?.id == 2) : payment;
  return (
    <div>
      <div className="fourth-step-wrapper fifth-step-wrapper">
        <label>اختر طريقة الدفع</label>
        <select id="paymentMethodSelect" onChange={(e) => setPaymentId(e.target.value)} className="form-select">
          <option value="0">اختر</option>
          {paymentMethods?.map((method) => {
            return wallet < totalPrice && method.id === 5 ? null : <option value={method.id}>{method.name}</option>;
          })}
        </select>
        {user?.establishment === "e14" && (
          <>
            <label>الوصف</label>
            <input className="form-control" placeholder="الوصف" onChange={(e) => setDescription(e.target.value)} />
            <div style={{ color: "red" }}>{descError}</div>
          </>
        )}
        <p style={{ color: "red", marginRight: "5px" }}>{error}</p>
        <p className="attention-payment">
          برجاء الانتباه انه فى حالة اختيار التسليم فى المنزل فسوف تقوم شركة الشحن بتوصيل طلبكم الى العنوان المسجل لدينا
          فى حسابكم الشخصي
        </p>
      </div>
    </div>
  );
};

export default FifthStep;
