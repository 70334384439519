import { LOADING, GET_ARTICLES, ARTICLE_LOADING, GET_ARTICLE_BY_ID } from '../actions/types'

const initialState = {
    news: [],
    new: [],
    updated: [],
    loading: true
}

const newsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ARTICLES:
            return {
                ...state,
                news: action.payload,
                loading: false
            };
        case GET_ARTICLE_BY_ID:
            return {
                ...state,
                new: action.payload,
                loading: false
            };
        case ARTICLE_LOADING:
            return {
                loading: true
            };
        case LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default newsReducer;