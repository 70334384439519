import { GET_BRAND, ADD_BRAND, GET_BRAND_BY_ID, BRAND_LOADING } from '../actions/types'

const initialState = {
    brands: [],
    brand: [],
    updated: [],
    loading: true
}

const brandsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BRAND:
            return {
                ...state,
                brands: action.payload,
                loading: false
            };
        case GET_BRAND_BY_ID:
            return {
                ...state,
                brand: action.payload?.data?.brand,
                loading: false
            };
        case ADD_BRAND:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case BRAND_LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default brandsReducer;