/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import AppPagination from "../components/Pagination";
import ProductCard from "../components/ProductsCard";
import AppSpinner from "../components/Spinner/Spinner";
import { FILTER } from "../redux/actions/types";

const ShopProducts = () => {
  //   const products = useSelector((state) => state.products.products?.products);
  const dispatch = useDispatch();
  const defaultFilters = useSelector((state) => state.filter);
  const [products, setProducts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [lastPage, setLastPage] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(defaultFilters.currentPage);
  const [paginated, setPaginated] = React.useState();
  const [currentId, setCurrentId] = React.useState(defaultFilters.currentId);
  const establishments = useSelector((state) => state.users.user?.category);
  const [currentEstablishment, setCurrentEstablishment] = React.useState(defaultFilters.currentEstablishment);
  const levels = establishments?.find((item) => item?.id === parseInt(currentEstablishment))?.children;
  const [currentLevel, setCurrentLevel] = React.useState(defaultFilters.currentLevel);
  const types = levels?.find((item) => item?.id === parseInt(currentLevel))?.children;
  const [currentType, setCurrentType] = React.useState(defaultFilters.currentType);
  const seasons = types?.find((item) => item?.id === parseInt(currentType))?.children;
  const [currentSeason, setCurrentSeason] = React.useState(0);

  //   React.useEffect(() => {
  //     if (parseInt(currentSeason) === 0) {
  //       setCurrentId(currentId);
  //     } else if (parseInt(currentType) === 0) {
  //       setCurrentId(currentId);
  //     } else if (parseInt(currentLevel) === 0) {
  //       setCurrentId(currentEstablishment);
  //     } else if (parseInt(currentEstablishment) === 0) {
  //       setCurrentId(14);
  //     }
  //   }, [currentEstablishment, currentLevel, currentType, currentSeason]);
  //   const loading = useSelector((state) => state.products.loading);
  const getProducts = () => {
    setLoading(true);
    axios
      .get(`https://bluepointwear.com/backend/ar/api/paginated-products/${currentId}?page=${currentPage}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setProducts(res.data?.products?.data);
        setLastPage(res.data?.products?.last_page);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    getProducts();
    window.scrollTo(0, 0);
    console.log("AHMED");
  }, [currentPage, currentId]);

  React.useEffect(() => {
    setCurrentPage(1);
  }, [currentId]);

  const filterData = {
    currentPage,
    currentEstablishment,
    currentType,
    currentLevel,
    currentId,
  };

  const filterAction = () => (dispatch) => {
    dispatch({
      type: FILTER,
      payload: filterData,
    });
  };

  React.useEffect(() => {
    dispatch(filterAction());
  }, [currentPage, currentEstablishment, currentType, currentLevel, currentId]);

  console.log("DEFAULT", currentId);

  return (
    <div className="container">
      <div>
        <h1 className="text-center bold mt-2">المنتجات</h1>
        <hr />
        <h5>فيلتر حسب</h5>
        <div className="selects-wrapper">
          <select
            onChange={(e) => {
              setCurrentEstablishment(e.target.value);
              setCurrentId(e.target.value);
            }}
            className="form-select filter-select"
            defaultValue={currentEstablishment}
          >
            <option value={14}> المدرسة</option>
            {establishments?.map((item, index) => {
              return (
                <option key={index} value={item?.id}>
                  {item?.name}
                </option>
              );
            })}
          </select>
          <select
            onChange={(e) => {
              setCurrentLevel(e.target.value);
              setCurrentId(e.target.value);
            }}
            className="form-select filter-select"
            defaultValue={currentLevel}
          >
            <option value={currentEstablishment}>المرحلة</option>
            {levels?.map((item, index) => {
              return (
                <option key={index} value={item?.id}>
                  {item?.name}
                </option>
              );
            })}
          </select>
          <select
            onChange={(e) => {
              setCurrentType(e.target.value);
              setCurrentId(e.target.value);
            }}
            className="form-select filter-select"
            defaultValue={currentType}
          >
            <option value={currentLevel}> النوع</option>
            {types?.map((item, index) => {
              return (
                <option key={index} value={item?.id}>
                  {item?.name}
                </option>
              );
            })}
          </select>
          {/* <select
            onChange={(e) => {
              setCurrentSeason(e.target.value);
              setCurrentId(e.target.value);
            }}
            className="form-select filter-select"
          >
            <option value={currentType}> الفصل</option>
            {seasons?.map((item, index) => {
              return (
                <option key={index} value={item?.id}>
                  {item?.name}
                </option>
              );
            })}
          </select> */}
        </div>
        <hr />
      </div>
      <div className="row ">
        {loading ? (
          <div className="p-5">
            <AppSpinner />
          </div>
        ) : products?.length === 0 ? (
          <h2 className="text-center mt-5 mb-5 pt-5 pb-5">لا توجد منتجات</h2>
        ) : (
          products?.map((row, index) => {
            return (
              <div key={index} className="col-lg-4 col-md-6 col-sm-12 flex-center">
                <ProductCard
                  code={row?.code}
                  isDiscount={row.has_discount}
                  fav={row.is_favorite}
                  id={row.id}
                  title={row.title}
                  price={row.price}
                  url={row.main_photo_original_path}
                />
              </div>
            );
          })
        )}
      </div>
      <div className="mt-4 mb-2">
        <AppPagination
          paginated={paginated}
          setPaginated={setPaginated}
          pagesNum={lastPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default ShopProducts;
