import { ADD_CAT, GET_CITIES, CITIES_LOADING, GET_CITY_BY_ID } from '../actions/types'

const initialState = {
    cities: [],
    area: [],
    updated: [],
    loading: true
}

const citiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CITIES:
            return {
                ...state,
                cities: action.payload,
                loading: false
            };
        case GET_CITY_BY_ID:
            return {
                ...state,
                area: action.payload,
                loading: false
            };
        case ADD_CAT:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case CITIES_LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default citiesReducer;