import React from "react";
import "./spinner.css";
function AppSpinner() {
  return (
    <div className="spinner-wrapper">
      <div className="loader"></div>
    </div>
  );
}

export default AppSpinner;
