import axios from "axios";
import { myToast } from "../../components/Toast";

export const sendInquiry = (data) => (dispatch) => {
  axios
    .post(`https://bluepointwear.com/backend/ar/api/contact-us`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then(myToast(`تم إرسال ${data.type === 2 ? "الشكوى" : "المقترح"} بنجاح`));
};
