import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CartContent from '../components/CartContent';
import { useSelector } from 'react-redux';
import { BiLoaderAlt } from 'react-icons/bi';

const ShoppingCart = () => {
    const history = useHistory();
    // console.log(window.innerWidth)
    const [totalCost, setTotalCost] = React.useState();
    const [cartLength, setCartLength] = React.useState(0);
    const loading = useSelector(state => state.carts.loading)
    React.useState(() => {
        document.title = 'Blue Point | السلة'
    }, [])
    return(
        <div>
            <h2 className='text-center bold'>السلة</h2>
            <CartContent setTotalCost={setTotalCost} setCartLength={setCartLength} checkLength />
            <p className='text-center'>جميع الاسعار شاملة ضريبة القيمة المضافة بنسبة 14%.</p>
            <h6 className='text-center'>
                عن طريق تقديم طلبك ، فإنك توافق علي 
                <span onClick={(() => history.push('/terms'))} className='link-in-text bold'> سياسية الخصوصية </span> و 
                <span onClick={(() => history.push('/terms'))} className='link-in-text bold'> شروط الاستخدام </span> اقرأ 
                <span onClick={(() => history.push('/terms'))} className='link-in-text bold'> سياسة الإستبدال والإسترجاع</span>.
            </h6>

            <div className='flex-center mt-4'>
                <div className='flex-between price-proceed-wrapper'>
                    <h4 className='bold'>المجموع: {loading ? <BiLoaderAlt className='login-loader' /> : totalCost} جنيه</h4>
                   <button disabled={loading? true : cartLength < 1? true: false} onClick={() => history.push('/checkout')} className='login-button mb-3'>انتقل إلى الدفع</button>
                </div>
            </div>

        </div>
    )
}

export default ShoppingCart;