import React from 'react';
import facebook from '../assets/Facebook.svg';
import whatsApp from '../assets/WhatsApp.svg'
import youtube from '../assets/Youtube.svg';
import instagram from '../assets/Instagram.svg';
import twitter from '../assets/Twitter.svg';
import google from '../assets/Google.svg';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTerms } from '../redux/actions/termsActions';
import visa from '../assets/visa.png';
import master from '../assets/master.png';
import arab from '../assets/arab.png';

const AppFooter = ({ auth }) => {
    const dispatch = useDispatch();
    const socialMedia = useSelector(state => state?.terms?.terms?.setting);
    // console.log('ING', socialMedia?.instagram)
    React.useEffect(() => {
        dispatch(getTerms())
    }, [dispatch])
    return (
        <div className='follow-us-wrapperREMOVE'>
            {/* <h2 className='follow-us-text'>تابعونا</h2>
            <div className='flex-center'>
                <div className='social-media-wrapper'>
                    <a href={socialMedia?.tiwtter}><img alt='Twitter' src={twitter} className='social-media-icon' /></a>
                    <a href={socialMedia?.youtube}><img alt='Youtube' src={youtube} className='social-media-icon' /></a>
                    <a href={socialMedia?.instagram}><img alt='Instagram' src={instagram} className='social-media-icon' /></a>
                    <a href={socialMedia?.facebook}><img alt='Facebook' src={facebook} className='social-media-icon' /></a>
                    <a href={socialMedia?.gmail}><img alt='Google' src={google} className='social-media-icon' /></a>
                </div>
            </div> */}

            <div className='footer-data'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-2 col-sm-6 footer-links'>
                            <div className='footer-logo-wrapper'>
                                <img style={{ maxWidth: '100px' }} src='/logo.png' alt='img' />
                            </div>
                            <div className='flex-between footer-icons-wrapper'>
                                <a href={socialMedia?.youtube}><img alt='Youtube' src={youtube} className='social-media-icon ' /></a>
                                <a href='https://wa.me/+201550773142'><img alt='Whatsapp' src={whatsApp} className='social-media-icon mr-5' /></a>
                            </div>
                        </div>
                        {
                            auth ?
                                <div className='col-md-3 col-sm-6 footer-links'>
                                    <h2>رعاية العملاء</h2>
                                    <Link style={{ textDecoration: "none" }} to='/terms'><p>الشروط و الأحكام وسياسة خصوصية البيانات</p></Link>
                                    <Link style={{ textDecoration: "none" }} to='/inquiries'><p>للشكاوى و المقترحات</p></Link>
                                    <Link style={{ textDecoration: "none" }} to='/support'><p>الأسئلة الشائعة</p></Link>
                                </div> : <div className='col-md-3 col-sm-6'> </div>
                        }
                        {
                            auth ?
                                <div className='col-md-2 col-sm-6 footer-links'>
                                    <h2>حسابي</h2>
                                    <Link style={{ textDecoration: "none" }} to='/my-profile'><p>حسابي</p></Link>
                                    <Link style={{ textDecoration: "none" }} to='/cart'><p>سلة المشتريات</p></Link>
                                    <Link style={{ textDecoration: "none" }} to='/wishlist'><p>المفضلة</p></Link>
                                    <Link style={{ textDecoration: "none" }} to='/orders'><p>طلباتي</p></Link>
                                </div> : null
                        }
                        {
                            !auth ? null :
                                <div className='col-md-2 col-sm-6 footer-links'>
                                    <h2>بلو بوينت</h2>
                                    <Link style={{ textDecoration: "none" }} to='/about'><p>عن التطبيق</p></Link>
                                    {auth ? <Link style={{ textDecoration: "none" }} to='/news'><p>الأخبار</p></Link> : null}
                                </div>
                        }
                        <div className='col-md-3 col-sm-6 footer-links'>
                            <h2>تواصل معنا</h2>
                            <p>البريد : NEW.CUSTOMERS@BLUEPOINTWEAR.COM</p>
                            <p>البريد : PARENTS.CARE@BLUEPOINTWEAR.COM</p>
                        </div>
                    </div>
                </div>
                <div className='flex-center'>
                    <img alt='img' className='visa' src={visa} />
                    <img alt='img' className='visa' src={master} />
                    <img alt='img' className='arab' src={arab} />
                </div>
            </div>


            {/* <div className='footer-data flex-center'>
                <div className='row footer-wrapper'>
                    <div className='col-sm-3 col-6 footer-links'>
                        <h2>رعاية العملاء</h2>
                        <p>الشروط و الأحكام وسياسة خصوصية البيانات</p>
                        <p>للشكاوى و المقترحات</p>
                        <p>الأسئلة الشائعة</p>
                    </div>
                    <div className='col-sm-3 col-6 footer-links'>
                        <h2>حسابي</h2>
                        <p>حسابي</p>
                        <p>سلة المشتريات</p>
                        <p>المفضلة</p>
                        <p>طلباتي</p>
                    </div>
                    <div className='col-sm-3 col-6 footer-links'>
                        <h2>بلو بوينت</h2>
                        <p>عن التطبيق</p>
                        <p>الأخبار</p>
                    </div>
                    <div className='col-sm-3 col-6 footer-links'>
                        <h2>تواصل معنا</h2>
                        <p>البريد : NEW.CUSTOMERS@BLUEPOINTWEAR.COM</p>
                        <p>البريد : PARENTS.CARE@BLUEPOINTWEAR.COM</p>
                        <p>الواتساب : 01550773142</p>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default AppFooter;