/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductCard from "../components/ProductsCard";
import AppSpinner from "../components/Spinner/Spinner";
import { setLoading } from "../redux/actions/productsActions";
import { getProducts } from "../redux/actions/productsActions";
import { getUserById, updateUser } from "../redux/actions/usersActions";

const Products = () => {
  const dispatch = useDispatch();
  const [showDown, setShowDown] = React.useState(false);
  const products = useSelector((state) => state.products.products?.products);
  const sub_category = useSelector((state) => state.users.user?.sub_category);
  const loading = useSelector((state) => state.products.loading);
  const user = useSelector((state) => state.users.user);
  const theAddress = JSON.parse(user?.address ? user?.address : '{"gov":"", "city":"", "city":""}');
  const categories = useSelector((state) => state.categories.categories?.categories);
  const [name, setName] = React.useState();
  const [age, setAge] = React.useState();
  const [email, setEmail] = React.useState(user?.email);
  const [mobile, setMobile] = React.useState(user?.mobile);
  const [near, setNear] = React.useState();
  const [gender, setGender] = React.useState();
  const [phone, setPhone] = React.useState(user?.phone);
  const [category, setCategory] = React.useState();
  const [mohafza, setMohafza] = React.useState(theAddress?.gov);
  const [city, setCity] = React.useState();
  const [district, setDistrict] = React.useState();
  const [street, setStreet] = React.useState();
  const [building, setBuilding] = React.useState();
  const [floor, setFloor] = React.useState();
  const [home, setHome] = React.useState();
  const [startTime, setStartTime] = React.useState();
  const [endTime, setEndTime] = React.useState();
  const [categoryId, setCategoryId] = React.useState(user?.category_id);
  const [boys, setBoys] = React.useState(null);
  const [boysToFilter, setBoysToFilter] = React.useState();
  const [stageName, setStageName] = React.useState(user?.sub_category?.name);

  React.useState(() => {
    document.title = "Blue Point | الرئيسية";
  }, []);

  React.useEffect(() => {
    setPhone(user?.phone);
    setAge(user?.age);
    setMobile(user?.mobile);
    setName(user?.name);
    setEmail(user?.email);
    setGender(user?.gender);
    setCategoryId(user?.category_id);
    setCity(theAddress?.city);
    setBuilding(theAddress?.home_number);
    setDistrict(theAddress?.area);
    setStreet(theAddress?.street);
    setStartTime(theAddress?.from);
    setEndTime(theAddress?.to);
    setFloor(theAddress?.floor);
    setMohafza(theAddress?.gov);
    setHome(theAddress?.apartment);
    setNear(theAddress?.near);
  }, [user]);

  React.useEffect(() => {
    dispatch(setLoading());
    dispatch(getProducts(categoryId));
  }, [categoryId]);

  React.useEffect(() => {
    if (boys !== null) {
      setBoysToFilter(sub_category?.children?.filter((row) => parseInt(boys) === row.id));
      // console.log('SSSSSSSS', boysToFilter)
      // console.log(boys)
    }
  }, [boys]);

  // console.log('RRRRRRR', boysToFilter)

  const getProfile = () => {
    dispatch(getUserById());
  };

  // React.useEffect(() => {
  //     dispatch(getUserById())
  // }, [])

  React.useEffect(() => {
    dispatch(setLoading());
    dispatch(
      updateUser(
        {
          name,
          age,
          gender,
          email,
          mobile,
          category,
          mohafza,
          city,
          district,
          street,
          building,
          floor,
          near,
          startTime,
          endTime,
          home,
          phone,
        },
        false,
        getProfile,
        () => {}
      )
    );
  }, [category]);

  const handleRadioChange = (e) => {
    setCategoryId(e.target.value);
  };
  const handleMainRadioChange = (e) => {
    setCategoryId(e.target.value);
    setBoys(e.target.value);
  };
  const handleStageChange = (id, row) => {
    setCategory(id);
    setCategoryId(id);
    setStageName(row.name);
    setShowDown(false);
  };

  console.log("CATEGORIES", categories);

  // console.log(stageName, 'STAGE')
  return loading === true || products ? (
    <div className="container">
      <h1 className="school-name">{user?.category?.name}</h1>
      <h5 onClick={() => setShowDown(!showDown)} className="school-name mt-2 pointer">
        <span>{stageName ? stageName : "برجاء اختيار مرحلة"}</span>
        <span className="down-icon">ᐁ</span>
      </h5>
      <div className={showDown ? "show-content stage-wrapper" : "hide-content stage-wrapper"}>
        {categories?.map((row, index) => {
          return row?.ref_code === 1 ? (
            <div key={index} className="flex-center">
              <div onClick={() => handleStageChange(row.id, row)} className="stage-dropdown">
                {row.name}
              </div>
            </div>
          ) : null;
        })}
      </div>

      <hr />
      <label>فلتر حسب</label>
      <div className="flex-between">
        <div className="flex-center">
          {/* <select defaultValue={categoryId} onChange={(e) => {
                        setCategoryId(e.target.value)
                        setBoys(e.target.value)
                    }} className='select-filter form-select' aria-label="Default select example">
                        <option value={sub_category?.id}>الكل</option>
                        {
                            sub_category?.children?.map((row, index) => {
                                return(
                                    <option key={index} value={row.id} >{row.name}</option>
                                )
                            })
                        }

                    </select> */}

          <div className="seasons-filter-wrapper">
            {
              <>
                <input
                  onClick={handleMainRadioChange}
                  defaultChecked={true}
                  type="radio"
                  id="alls"
                  name="mainCat"
                  value={sub_category?.id}
                />
                <label htmlFor="alls">الكل</label>
              </>
            }
            {sub_category?.children?.map((row, index) => {
              return row?.ref_code === 1 ? (
                <div key={index}>
                  <input
                    onClick={handleMainRadioChange}
                    type="radio"
                    id={`main${index}`}
                    name="mainCat"
                    value={row.id}
                  />
                  <label htmlFor={`main${index}`}>{row.name}</label>
                </div>
              ) : null;
            })}
          </div>
        </div>

        <div className="choose-color">
          <div className="flex-between">
            <div className="seasons-filter-wrapper">
              {boysToFilter?.length > 0 ? (
                <>
                  <input
                    onClick={handleRadioChange}
                    defaultChecked={true}
                    type="radio"
                    id="all"
                    name="radioFruit"
                    value={boysToFilter ? boysToFilter[0]?.id : null}
                  />
                  <label htmlFor="all">الكل</label>
                </>
              ) : null}
              {boysToFilter
                ? boysToFilter[0]?.children?.map((row, index) => {
                    return row?.ref_code === 1 ? (
                      <div key={index}>
                        <input onClick={handleRadioChange} type="radio" id={index} name="radioFruit" value={row.id} />
                        <label htmlFor={index}>{row.name}</label>
                      </div>
                    ) : null;
                  })
                : null}
            </div>
          </div>
        </div>

        <p className="mb-0">{products?.length} منتج</p>
      </div>
      <hr />

      <div className="row ">
        {loading ? (
          <AppSpinner />
        ) : (
          products?.map((row, index) => {
            return (
              <div key={index} className="col-lg-4 col-md-6 col-sm-12 flex-center">
                <ProductCard
                  code={row?.code}
                  isDiscount={row.has_discount}
                  fav={row.is_favorite}
                  id={row.id}
                  title={row.title}
                  price={row.price}
                  url={row.main_photo_original_path}
                />
              </div>
            );
          })
        )}
      </div>
    </div>
  ) : (
    <AppSpinner />
  );
};

export default Products;
