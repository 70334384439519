import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import { LOGIN_ERROR, LOGIN, VERIFY, FORGET_PASSWORD, CHANGE_PASSWORD, REGISTER, REGISTER_FAIL, FORGET_PASSWORD_FAIL } from './types'
import { myError, myToast } from '../../components/Toast';


toast.configure();


export const login = (userData, rememberMe, move) => dispatch => {
     axios.post(`https://bluepointwear.com/backend/ar/api/login`, userData)
          .then(res => {
               // dispatch({
               //      type: LOGIN,
               //      payload: res.data
               // })
               // localStorage.setItem('auth', true)
               if (!res.data.msg) {
                    dispatch({
                         type: 'SSSS',
                         payload: res.data
                    })
                    localStorage.setItem('auth', true)
                    localStorage.setItem('token', res?.data?.token)
                    window.location.reload();
               } else {
                    myToast('تم إرسال الرمز عبر الهاتف')
                    move()
                    dispatch({
                         type: VERIFY,
                         payload: res.data
                    })
                    // alert(res.data.msg)
               }
          })
          .catch(error => {
               dispatch({
                    type: LOGIN_ERROR,
                    payload: error
               })
               if (error.response?.status === 403) {
                    myError(error.response?.data?.msg)
               } else {
                    myError('الرقم غير متصل بحساب قم بإنشاء حساب جديد')
               }
               console.log(error.response?.status)
          })
}


export const verifyCode = (userData, redirection) => dispatch => {
     axios.post(`https://bluepointwear.com/backend/ar/api/verifyCode`, userData)
          .then(res => {
               // dispatch({
               //      type: LOGIN,
               //      payload: res.data
               // })
               // localStorage.setItem('auth', true)
               if (!res.data.msg) {
                    redirection()
                    dispatch({
                         type: 'SSSS',
                         payload: res.data
                    })
                    localStorage.setItem('auth', true)
                    localStorage.setItem('token', res?.data?.token)
                    window.location.reload();
                    myToast('تم تسجيل الدخول بنجاح')
               } else {
                    dispatch({
                         type: 'SSSS',
                         payload: res.data
                    })
                    // alert(res.data.msg)
               }
          })
          .catch(error => {
               dispatch({
                    type: LOGIN_ERROR,
                    payload: error
               })
               if (error.response?.status === 403) {
                    myError(error.response?.data?.msg)
               } else {
                    myError('خطأ في البيانات')
               }
               console.log(error.response?.status)
          })
}


export const forgetPassword = (theNumber) => dispatch => {
     axios.post('https://bluepointwear.com/backend/ar/api/forgetPassword', theNumber)
          .then(res => {
               // alert(res?.data)
               dispatch({
                    type: FORGET_PASSWORD,
                    payload: res.data
               })
               myToast('تم إرسال الكود بنجاح على الهاتف')
          })
          .catch(error => {
               dispatch({
                    type: FORGET_PASSWORD_FAIL,
                    payload: error
               })
          })
}

export const changePassword = (theNumber) => dispatch => {
     axios.post('https://bluepointwear.com/backend/ar/api/changePassword', theNumber)
          .then(res => {
               dispatch({
                    type: CHANGE_PASSWORD,
                    payload: res.data
               })
               myToast('تم تغيير كلمة السر بنجاح')
          })
          .catch(error => {
               dispatch({
                    type: FORGET_PASSWORD_FAIL,
                    payload: error
               })
          })
}

export const register = (userData) => dispatch => {
     axios.post('https://bluepointwear.com/backend/ar/api/register', userData)
          .then(res => {
               dispatch({
                    type: REGISTER,
                    payload: res.data
               })
               myToast('تم التسجيل بنجاح في انتظار الموافقة')
          })
          .catch(error => {
               dispatch({
                    type: REGISTER_FAIL,
                    payload: error
               })
          })
}

