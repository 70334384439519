import React from 'react';
import { BiLoaderAlt } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { myError } from '../components/Toast';
import { confirmMobile } from '../redux/actions/usersActions';

const ChangeMobileConfirmation = () => {
    const [mobile, setMobile] = React.useState();
    const [code, setCode] = React.useState();
    const [loading, setLoading] = React.useState(false)
    const [replace, setReplace] = React.useState(false);
    const changeError = useSelector(state => state?.users?.mobileConfirmError);
    const history = useHistory();
    const dispatch = useDispatch();
    const handleSubmit = (e) => {
        e.preventDefault();
        if (mobile?.length !== 11) {
            myError('يجب ان يكون رقم الهاتف 11 رقم')
        } else {
            dispatch(confirmMobile({ mobile, verification_code: code }, setReplace, setLoading))
        }
    }

    React.useEffect(() => {
        if (replace) {
            history.push('/my-profile')
            window.location.reload();
        }
    }, [replace, history])

    // console.log(changeError, 'eeeeeeee')
    return (
        <div className='container'>
            <div className='flex-center'>
            </div>
            <h2 className='text-center bold'>حسابي</h2>
            <h4 className='text-center bold mb-4'>تغيير رقم الهاتف</h4>
            <div className='row update-info-container'>
                <form onSubmit={handleSubmit}>
                    <div className='row mt-3'>
                        <label>رقم الهاتف الجديد</label>
                        <input onChange={(e) => setMobile(e.target.value)} placeholder='ادخل رقم الهاتف الجديد' className='form-control mb-2' />
                        <p className='register-error'>{changeError?.mobile ? changeError?.mobile[0] : null}</p>
                        <button className='login-button profile-buttons'>{loading ? <BiLoaderAlt className='login-loader' /> : "تغيير رقم الهاتف"}</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ChangeMobileConfirmation;