import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/actions/authActions";
import { setLoading } from "../redux/actions/loading";
import { BiLoaderAlt } from "react-icons/bi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { myError } from "../components/Toast";
import PasswordInput from "../components/PasswordInput";
import parse from "html-react-parser";

const LoginPage = () => {
  const history = useHistory();
  const [mobile, setMobile] = React.useState("");
  const [password, setPassword] = React.useState("");
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.login.loading);
  const error = useSelector((state) => state.login.errorMessage);
  const verify = useSelector((state) => state.login.verify);
  const errorListener = useSelector((state) => state.login.listener);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [rememberMe, setRememberMe] = React.useState(false);
  const text = useSelector((state) => state.terms?.terms?.setting?.login_page);

  const handleRememberChange = (e) => {
    if (e.target.checked) {
      setRememberMe(true);
    } else {
      setRememberMe(false);
    }
  };

  const move = () => {
    history.push(`/verify/${mobile}`);
  };

  React.useEffect(() => {
    setErrorMessage(error);
  }, [error, errorListener]);

  React.useEffect(() => {
    setErrorMessage(" ");
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (mobile.length > 11) {
      myError("يجب الا يزيد الرقم عن 11 رقم");
      setErrorMessage("يجب الا يزيد الرقم عن 11 رقم");
    } else {
      dispatch(setLoading());
      dispatch(login({ mobile, password }, rememberMe, move));
      history.push("/");
    }
  };

  if (verify) {
    history.push(`/verify/${mobile}`);
  }
  return (
    <div className="flex-center">
      <div className="container">
        <div className="flex-center login-logo-wrapper">
          <img className="logo-limits" alt="logo" src={logo} />
        </div>

        <div className="row login-row container">
          <div className="col-12 flex-center">
            <form className="mr-5" onSubmit={handleSubmit}>
              <h4 className="text-center login-word mb-4">تسجيل الدخول</h4>
              <p className="text-color mb-1">إذا كان لديك حساب معنا يرجى تسجيل الدخول</p>

              <label>رقم الهاتف</label>
              <input
                required
                type="number"
                onChange={(e) => setMobile(e.target.value)}
                className="form-control app-input"
                placeholder="رقم الهاتف"
              />

              <label>كلمة المرور</label>
              <PasswordInput onChange={(e) => setPassword(e.target.value)} placeholder="كلمة المرور" />
              {/* <div className='checkbox-wrapper mb-2'>
                            <input onChange={handleRememberChange} className='app-checkbox' type='checkbox' />
                            <label>تذكرني </label>
                            </div> */}
              <div className="flex-between mt-4">
                <button type="submit" className=" login-button fixed-login">
                  {loading ? <BiLoaderAlt className="login-loader" /> : "تسجيل الدخول"}
                </button>

                <Link to="/register">
                  <button type="button" className="login-button mr-4">
                    إنشاء حساب
                  </button>
                </Link>
              </div>
              <p className="login-error">{errorMessage}</p>
              <Link style={{ textDecoration: "none" }} to="/forget-password">
                <p className="mt-2 forget-password">نسيت كلمة المرور؟</p>
              </Link>
            </form>
          </div>

          <div className="col-12 how-to-create"></div>

          <div className="mt-3">{text?.length > 3 ? parse(text) : null}</div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
