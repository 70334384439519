import React from "react";
import { BiLoaderAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import RedStar from "../components/RedStar";
import AppSpinner from "../components/Spinner/Spinner";
import { myError } from "../components/Toast";
import { getAreas } from "../redux/actions/citiesActions";
import { setLoading } from "../redux/actions/loading";
import { getUserById, updateUser } from "../redux/actions/usersActions";

const MyProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.users.user);
  const categories = useSelector((state) => state.categories.categories?.categories);
  const mohafzat = useSelector((state) => state.cities.cities?.cities);
  const loading = useSelector((state) => state.users.loading);
  const cities = useSelector((state) => state.cities.area?.areas);
  const theAddress = JSON.parse(user?.address ? user?.address : '{"gov":"", "city":"", "city":""}');
  const [name, setName] = React.useState();
  const [age, setAge] = React.useState();
  const [email, setEmail] = React.useState(user?.email);
  const [mobile, setMobile] = React.useState(user?.mobile);
  const [near, setNear] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [phone, setPhone] = React.useState(user?.phone === null ? "" : user?.phone);
  const [category, setCategory] = React.useState();
  const [mohafza, setMohafza] = React.useState(theAddress?.gov);
  const [city, setCity] = React.useState("");
  const [district, setDistrict] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [building, setBuilding] = React.useState("");
  const [floor, setFloor] = React.useState("");
  const [home, setHome] = React.useState("");
  const [startTime, setStartTime] = React.useState("");
  const [endTime, setEndTime] = React.useState("");
  const choosenGov = mohafzat?.find((gov) => gov.text === mohafza);
  const [renderSelect, setRenderSelect] = React.useState(true);

  React.useEffect(() => {
    setRenderSelect(false);
    setTimeout(() => {
      setRenderSelect(true);
    }, 1000);
  }, []);
  React.useState(() => {
    document.title = "Blue Point | صفحتي";
  }, []);
  React.useEffect(() => {
    dispatch(getAreas(choosenGov?.id));
  }, [choosenGov, dispatch]);

  const redirect = () => {
    history.push("/");
  };

  React.useEffect(() => {
    setPhone(user?.phone);
    user?.age && setAge(user?.age);
    setMobile(user?.mobile);
    setName(user?.name);
    setEmail(user?.email);
    user?.gender && setGender(user?.gender);
    user?.category && setCategory(user?.category_id);
    if (user?.address) {
      setCity(theAddress?.city);
      setBuilding(theAddress?.home_number);
      setDistrict(theAddress?.area);
      setStreet(theAddress?.street);
      setStartTime(theAddress?.from);
      setEndTime(theAddress?.to);
      setFloor(theAddress?.floor);
      setMohafza(theAddress?.gov);
      setHome(theAddress?.apartment);
      setNear(theAddress?.near);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getProfile = () => {
    dispatch(getUserById());
  };

  const data = {
    name,
    age,
    gender,
    email,
    mobile,
    category,
    mohafza,
    city,
    district,
    street,
    building,
    floor,
    near,
    startTime,
    endTime,
    home,
    phone,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (parseInt(category) === 0 || category === null || category === "null") {
      myError("برجاء اختيار المرحلة");
    } else if (parseInt(gender) === 0 || gender === null || gender === "null") {
      myError("برجاء اختيار النوع");
    } else if (parseInt(mohafza) === 0 || mohafza === "") {
      myError("برجاء اختيار المحافظة");
    } else if (parseInt(city) === 0 || city === "") {
      myError("برجاء اختيار المدينة");
    } else {
      dispatch(setLoading());
      dispatch(updateUser(data, true, getProfile, redirect));
    }
  };

  console.log("DATA", data);

  // console.log(category)

  console.log("AGE", theAddress?.gov);

  // console.log(typeof theAddress?.city, 'cittyyyy')
  return user?.id > 0 ? (
    <div className="container">
      <div className="flex-center"></div>
      <h2 className="text-center bold">حسابي</h2>
      <h4 className="text-center bold mb-4">تعديل البيانات الشخصية</h4>
      <form onSubmit={handleSubmit}>
        <div className="row update-info-container">
          <div className="col-md-6">
            <label>
              الاسم بالكامل <RedStar />
            </label>
            <input
              required
              defaultValue={user?.name}
              onChange={(e) => setName(e.target.value)}
              className="form-control app-input"
              placeholder="الاسم رباع"
            />
          </div>
          <div className="col-md-3">
            <label>
              العمر <RedStar />
            </label>
            <input
              required
              defaultValue={age}
              onChange={(e) => setAge(e.target.value)}
              className="form-control app-input"
              placeholder="العمر"
            />
          </div>
          <div className="col-md-3">
            <label>
              البريد الإلكتروني <RedStar />
            </label>
            <input
              required
              defaultValue={user?.email}
              disabled
              onChange={(e) => setEmail(e.target.value)}
              className="form-control app-input"
              placeholder="البريد الإلكتروني"
            />
          </div>
          <div className="col-md-3">
            <label>
              رقم الهاتف <RedStar />
            </label>
            <input
              required
              defaultValue={user?.mobile}
              disabled
              onChange={(e) => setMobile(e.target.value)}
              className="form-control app-input"
              placeholder="رقم الهاتف"
            />
          </div>
          <div className="col-md-3">
            <label>رقم هاتف اخر</label>
            <input
              type="number"
              defaultValue={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="form-control app-input"
              placeholder="رقم هاتف اخر"
            />
          </div>
          <div className="col-md-3">
            <label>
              الفئه <RedStar />
            </label>
            {renderSelect ? (
              <select
                defaultValue={user?.category_id}
                onChange={(e) => setCategory(e.target.value)}
                className="form-select app-input"
              >
                <option value="0">اختر المرحلة</option>
                {categories?.map((row, index) => {
                  return row?.ref_code === 1 ? (
                    <option key={index} value={row.id}>
                      {row.name}
                    </option>
                  ) : null;
                })}
              </select>
            ) : (
              false
            )}
          </div>
          <div className="col-md-3">
            <label>
              النوع <RedStar />
            </label>
            {renderSelect ? (
              <select
                defaultValue={user?.gender}
                onChange={(e) => setGender(e.target.value)}
                className="form-select app-input"
              >
                <option value="0">اختر</option>
                <option value="male">ذكر</option>
                <option value="female">انثى</option>
              </select>
            ) : null}
          </div>

          <h2 className="bold text-center mt-4">العنوان</h2>
          <hr />

          <div className="col-md-4">
            <label>
              المحافظة <RedStar />
            </label>
            {renderSelect ? (
              <select
                defaultValue={theAddress?.gov}
                onChange={(e) => setMohafza(e.target.value)}
                className="form-select app-input"
              >
                <option value={0}>اختر محافظة</option>
                {mohafzat?.map((row, index) => {
                  return (
                    <option key={index} value={row.text}>
                      {row.text}
                    </option>
                  );
                })}
              </select>
            ) : null}
          </div>
          <div className="col-md-4">
            <label>
              المدينة <RedStar />
            </label>
            {renderSelect ? (
              <select
                defaultValue={theAddress?.city}
                onChange={(e) => setCity(e.target.value)}
                className="form-select app-input"
              >
                <option value={0}>اختر مدينة</option>
                {cities?.map((row, index) => {
                  return (
                    <option key={index} value={row.text}>
                      {row.text}
                    </option>
                  );
                })}
              </select>
            ) : null}
          </div>
          <div className="col-md-4">
            <label>
              المنطقة <RedStar />
            </label>
            <input
              required
              defaultValue={theAddress?.area}
              onChange={(e) => setDistrict(e.target.value)}
              className="form-control app-input"
              placeholder="المنطقة"
            />
          </div>
          <div className="col-md-4">
            <label>
              الشارع <RedStar />
            </label>
            <input
              required
              defaultValue={theAddress?.street}
              onChange={(e) => setStreet(e.target.value)}
              className="form-control app-input"
              placeholder="الشارع"
            />
          </div>
          <div className="col-md-4">
            <label>
              رقم المنزل <RedStar />
            </label>
            <input
              required
              defaultValue={theAddress?.home_number}
              onChange={(e) => setBuilding(e.target.value)}
              className="form-control app-input"
              placeholder="رقم المنزل"
            />
          </div>
          <div className="col-md-4">
            <label>
              الطابق <RedStar />
            </label>
            <input
              required
              defaultValue={theAddress?.floor}
              onChange={(e) => setFloor(e.target.value)}
              className="form-control app-input"
              placeholder="الطابق"
            />
          </div>
          <div className="col-md-4">
            <label>
              الشقه <RedStar />
            </label>
            <input
              required
              defaultValue={theAddress?.apartment}
              onChange={(e) => setHome(e.target.value)}
              className="form-control app-input"
              placeholder="الشقه"
            />
          </div>
          <div className="col-md-4">
            <label>
              قريباً من <RedStar />
            </label>
            <input
              defaultValue={theAddress?.near}
              onChange={(e) => setNear(e.target.value)}
              className="form-control app-input"
              placeholder="قريب من"
            />
          </div>
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-6">
                <label>موعد التسليم المفضل</label>
                <input
                  defaultValue={theAddress?.from}
                  type="time"
                  onChange={(e) => setStartTime(e.target.value)}
                  className="form-control app-input"
                  placeholder="الشقه"
                />
              </div>
              <div className="col-md-6">
                <label></label>
                <input
                  defaultValue={theAddress?.to}
                  type="time"
                  onChange={(e) => setEndTime(e.target.value)}
                  className="form-control app-input mt-1"
                  placeholder="الشقه"
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-3 mb-2 flex-center">
              <button className="login-button profile-buttons">
                {loading ? <BiLoaderAlt className="login-loader" /> : "تأكيد"}
              </button>
            </div>
            <div className="col-md-3 mb-2 flex-center">
              <button
                onClick={() => history.push("/change-password")}
                className="login-button profile-buttons red-butt"
              >
                تغيير كلمة السر
              </button>
            </div>
            <div className="col-md-3 mb-2 flex-center">
              <button
                onClick={() => history.push("/change-mobile/confirmation")}
                className="login-button profile-buttons red-butt"
              >
                تغيير رقم الهاتف
              </button>
            </div>
            <div className="col-md-3 mb-2 flex-center">
              <button onClick={() => history.push("/confirm-email")} className="login-button profile-buttons red-butt">
                تغيير البريد الإلكتروني
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  ) : (
    <AppSpinner />
  );
};

export default MyProfile;
