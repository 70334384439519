import React from "react";
import { AiOutlineShoppingCart, AiOutlineHeart, AiOutlineLogout } from "react-icons/ai";
import { BsPersonCircle } from "react-icons/bs";
import { Container, Navbar, NavDropdown, Nav } from "react-bootstrap";
import logo from "../assets/logo.png";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { getCart } from "../redux/actions/cartsActions";
import { useLocation } from "react-router-dom";
import ConfirmationModal from "./ConfirmationModal";
import { MdOutlineAccountBalanceWallet, MdOutlineArticle } from "react-icons/md";

const AppNavbar = () => {
  const [show, setShow] = React.useState(false);
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [cartLength, setCartLength] = React.useState(0);
  const pushTo = (path) => {
    history.push(`/${path}`);
  };
  const logout = () => {
    setShow(true);
  };
  const user = useSelector((state) => state.users.user);
  const cartItems = useSelector((state) => state.carts.carts?.cart);
  React.useEffect(() => {
    const arrayOfItemsLength = cartItems?.map((item, index) => item?.quantity);
    console.log("ARRAY OF CART ITEMS", arrayOfItemsLength);
    setCartLength(arrayOfItemsLength?.reduce((total, current) => total + current, 0));
  }, [cartItems]);
  React.useEffect(() => {
    dispatch(getCart());
  }, [pathname, dispatch]);

  // console.log('PATH', pathname)
  return (
    <>
      <ConfirmationModal show={show} setShow={setShow} content="تسجيل الخروج" title="تسجيل الخروج" logout />
      <Navbar className="app-nav-bar" expand="lg">
        <Container>
          <Link to="/">
            <div>
              <img alt="logo" src={logo} className="navbar-logo" />
              <span> </span>
              <img alt="logo" src={user?.category?.photo_original_path} className="navbar-logo" />
            </div>
          </Link>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link onClick={() => history.push("/orders")} className="nav-links">
                <MdOutlineArticle className="nav-icon" />{" "}
                <span
                  className={`nav-links ${pathname === "/orders" || pathname.includes("/view-order") ? "blue" : null}`}
                >
                  {user?.establishment === "e14" ? "فواتيري" : "طلباتي"}
                </span>
              </Nav.Link>
              {/* <Nav.Link
                onClick={() => history.push("/wallet")}
                className="nav-links"
              >
                <MdOutlineAccountBalanceWallet className="nav-icon" />{" "}
                <span
                  className={`nav-links ${
                    pathname === "/wallet" || pathname.includes("/view-order")
                      ? "blue"
                      : null
                  }`}
                >
                  محفظتي
                </span>
              </Nav.Link> */}
              <Nav.Link onClick={() => history.push("/wishlist")} className="nav-links">
                <AiOutlineHeart className="nav-icon" />{" "}
                <span className={`nav-links ${pathname === "/wishlist" ? "blue" : null}`}>المفضلة</span>
              </Nav.Link>
              <Nav.Link onClick={() => pushTo("cart")} className="nav-links">
                <AiOutlineShoppingCart className="nav-icon" />
                <span className={`nav-links ${pathname === "/cart" ? "blue" : null}`}>
                  السلة
                  <span className="cart-notification">{cartLength}</span>
                </span>
              </Nav.Link>
              <Nav.Link onClick={() => history.push("/my-profile")} className="nav-links">
                <BsPersonCircle className="nav-icon" />{" "}
                <span className={`nav-links ${pathname === "/my-profile" ? "blue" : null}`}>
                  {user?.name.split(" ").slice(0, -1).join(" ")}
                </span>
              </Nav.Link>
              <Nav.Link onClick={logout} className="nav-links">
                <AiOutlineLogout className="nav-icon" /> <span className={`nav-links`}>تسجيل الخروج</span>
              </Nav.Link>
              {/* <NavDropdown title={<span className={`nav-links ${pathname === '/my-profile' ? 'blue' : null}`}>{user?.name.split(' ').slice(0, -1).join(' ')}</span>}>
                            <div onClick={() => pushTo('my-profile')} className='dropdown-item'><BsPersonCircle /> حسابي</div>
                            <div onClick={logout} className='dropdown-item'><AiOutlineLogout /> تسجيل الخروج</div>
                        </NavDropdown> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {user?.establishment === "e0" ? null : (
        <div className="flex-center container">
          <div className="flex-between home-page-headers-wrapper">
            <p onClick={() => history.push("/")} className={`home-page-headers ${pathname === "/" ? "blue" : null}`}>
              الصفحة الرئيسية
            </p>
            <p
              onClick={() => history.push("/offers")}
              className={`home-page-headers ${pathname === "/offers" ? "blue" : null}`}
            >
              متجر بلوبوينت{" "}
              <span
                style={{
                  fontSize: "8px",
                  color: "#fff",
                  background: "red",
                  borderRadius: "0px 0px 10px 10px",
                  padding: "3px 4px",
                  marginRight: "-2px",
                }}
              >
                جديد
              </span>
            </p>
            <p
              onClick={() => history.push("/news")}
              className={`home-page-headers ${pathname === "/news" ? "blue" : null}`}
            >
              الأخبار
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default AppNavbar;
