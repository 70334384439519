import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import logo from '../assets/logo.png';
import { register } from "../redux/actions/authActions";
import { setLoading } from '../redux/actions/loading';
import { BiLoaderAlt } from 'react-icons/bi';
import PasswordInput from "../components/PasswordInput";
import parse from 'html-react-parser';
import RedStar from "../components/RedStar";
import { myError } from '../components/Toast'

const CompleteRegistration = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [firstName, setFirstName] = React.useState();
    const [secondName, setSecondName] = React.useState();
    const [thirdName, setThirdName] = React.useState();
    const [fourthName, setFourthName] = React.useState();
    const [passwordError, setPasswordError] = React.useState();
    const [roleError, setRoleError] = React.useState();
    const [knowError, setKnowError] = React.useState();
    const [mobile, setMobile] = React.useState();
    const [email, setEmail] = React.useState();
    const [password, setPassword] = React.useState();
    const [establishment, setEstablishment] = React.useState();
    const [knowAboutUs, setKnowAboutUs] = React.useState(' ');
    const [acceptPrivacy, setAcceptPrivacy] = React.useState();
    const [role, setRole] = React.useState(' ');
    const text = useSelector(state => state.terms?.terms?.setting?.register_page)


    const done = useSelector(state => state.login.registerDone);
    const loading = useSelector(state => state.login.loading);
    const errors = useSelector(state => state.login.registerErrors)

    const handlePrivacyChange = (e) => {
        if (e.target.checked === true) {
            setAcceptPrivacy(1)
        } else {
            setAcceptPrivacy(null)
        }
    }

    if (done) {
        history.push('/')
    }

    React.useEffect(() => {
        if (password?.length > 6) {
            setPasswordError('')
        }
        if (knowAboutUs?.length > 2) {
            setKnowError('')
        }
        if (role?.length > 2) {
            setRoleError('')
        }
    }, [password, role, knowAboutUs])


    const userData = {
        first_name: firstName,
        second_name: secondName,
        third_name: thirdName,
        last_name: fourthName,
        mobile,
        email,
        know_about_us: knowAboutUs,
        accept_privacy: acceptPrivacy,
        who_am_i: role,
        establishment,
        password

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password.length < 6) {
            setPasswordError('الحد الاقصى لكلمة المرور 6 رموز')
        } else if (knowAboutUs === ' ') {
            setKnowError('يشترط اختيار من اين عرفتنا اولاً')
        } else if (role === ' ') {
            setRoleError('يشترط اختيار الوظيفة اولاً')
        } else if (mobile.length !== 11) {
            myError('رقم الهاتف يجب ان يكون 11 رقم')
        } else {
            dispatch(setLoading())
            dispatch(register(userData))
        }
    }

    return (
        <div>
            <div className='flex-center login-logo-wrapper'>
                <img className="logo-limits" alt='logo' src={logo} />
            </div>

            <div>
                <h4 className='text-center login-word mb-3'>إنشاء حساب جديد</h4>
                <p className='text-center text-color mb-1'>برجاء تسجيل الإسم ورقم الهاتف الذي تم تسجيلهم في الجهه المراد شراء الزي لها</p>
                <h6 className="text-center blue">النجمه الحمراء ترمز لأن الحقل مطلوب</h6>
            </div>

            <div className='flex-center container'>
                <form className='register-form' onSubmit={handleSubmit}>
                    <label>الاسم بالكامل <RedStar /></label>
                    <div className='flex-center'>
                        <input required onChange={(e) => setFirstName(e.target.value)} className='form-control app-input' placeholder='الاسم الاول' />
                        <input required onChange={(e) => setSecondName(e.target.value)} className='form-control app-input' placeholder='الاسم الثاني' />
                        <input required onChange={(e) => setThirdName(e.target.value)} className='form-control app-input' placeholder='الاسم الثالث' />
                        <input required onChange={(e) => setFourthName(e.target.value)} className='form-control app-input' placeholder='الاسم الرابع' />
                    </div>

                    <label>رقم الهاتف <RedStar /></label>
                    <input required onChange={(e) => setMobile(e.target.value)} className='form-control app-input' placeholder='رقم الهاتف' />
                    <p className="register-error">{errors?.mobile ? errors.mobile[0] : null}</p>

                    <label>البريد الالكتروني <RedStar /> برجاء كتابة إيميل صحيح لإستخدامه في متابعة الطلبات</label>
                    <input name='complete-reg-email' required onChange={(e) => setEmail(e.target.value)} className='form-control app-input' placeholder='البريد الالكتروني' />
                    <p className="register-error">{errors?.email ? errors.email[0] : null}</p>

                    <label>كلمة المرور <RedStar /></label>
                    <PasswordInput name='complete-reg-password' onChange={(e) => setPassword(e.target.value)} placeholder='كلمة المرور' />
                    <p className="register-error">{passwordError}</p>

                    <label>برجاء كتابة أسم الجهه المراد شراء الزى لها مثل ( أسم المدرسة أو أسم الشركة ....) <RedStar /></label>
                    <input required onChange={(e) => setEstablishment(e.target.value)} className='form-control app-input' placeholder='برجاء كتابة أسم الجهه المراد شراء الزى لها' />

                    <label>صفة العميل <RedStar /></label>
                    <select required onChange={(e) => setRole(e.target.value)} className="form-select app-input">
                        <option value=' '>اختر</option>
                        <option value='طالب'>طالب</option>
                        <option value='ولي امر'>ولي امر</option>
                        <option value='تاجر'>تاجر</option>
                        <option value='صاحب مدرسة'>صاحب مدرسة</option>
                    </select>
                    <p className="register-error">{roleError}</p>

                    <label>من اين عرفت الموقع <RedStar /></label>
                    <select required onChange={(e) => setKnowAboutUs(e.target.value)} className="form-select app-input">
                        <option value=' '>اختر</option>
                        <option value='من صديق'>من الاعلانات</option>
                        <option value='من الاعلانات'>من صديق</option>
                        <option value='من الجهة'>من الجهة</option>
                    </select>
                    <p className="register-error">{knowError}</p>

                    <div className='checkbox-wrapper mt-3'>
                        <input onChange={handlePrivacyChange} className='app-checkbox' type='checkbox' />
                        <label>اوافق لى بنود سياسة الاستخدام <RedStar /></label>
                    </div>
                    <p className="register-error">{errors?.accept_privacy ? "يشترط الموافقه على بنود سياسة الاستخدام اولاً" : null}</p>

                    <button style={{ width: '160px' }} className='login-button mt-3 mb-4'>{
                        loading ? <BiLoaderAlt className='login-loader' /> : 'إنشاء حساب جديد'
                    }</button>
                </form>
            </div>
            <div className='mb-1 mr-5 p-2'>
                {
                    text?.length > 3 ?
                        parse(text) : null
                }
            </div>


        </div>
    )
}

export default CompleteRegistration;