/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BiLoader } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getAreas } from "../redux/actions/citiesActions";
import RedStar from "./RedStar";

const SecondStep = ({ disable, setDisable, setShippingData, setStop, setStopBranch, setResId, setRes }) => {
  const dispatch = useDispatch();
  const [shippingWay, setShippingWay] = React.useState("0");
  const [reservation, setReservation] = React.useState(false);
  const [reservationId, setReservationId] = React.useState(0);
  const user = useSelector((state) => state.users.user);
  const methods = useSelector((state) => state.checkout?.checkout?.methods);
  const reservations = useSelector((state) => state.checkout?.checkout?.reservation);
  const address = useSelector((state) => state.checkout?.checkout?.address);
  const branches = useSelector((state) => state.checkout?.checkout?.branches);
  const mohafzat = useSelector((state) => state.cities.cities?.cities);
  const cities = useSelector((state) => state.cities.area?.areas);
  // const mohafzat = useSelector(state => state.cities.cities?.cities)
  // const cities = useSelector(state => state.cities.area?.areas)
  const theAddress = JSON.parse(address ? address : '{"gov":"", "city":"", "city":""}');
  const [near, setNear] = React.useState(theAddress?.near);
  const [mohafza, setMohafza] = React.useState(theAddress?.gov);
  const [city, setCity] = React.useState(theAddress?.city);
  const [district, setDistrict] = React.useState(theAddress?.area);
  const [street, setStreet] = React.useState(theAddress?.street);
  const [building, setBuilding] = React.useState(theAddress?.home_number);
  const [floor, setFloor] = React.useState(theAddress?.floor);
  const [home, setHome] = React.useState(theAddress?.apartment);
  const [startTime, setStartTime] = React.useState(theAddress?.from);
  const [endTime, setEndTime] = React.useState(theAddress?.to);
  const [branchId, setBranchId] = React.useState("");
  const [shippingFees, setShippingFees] = React.useState();
  const [saveAddress, setSaveAddress] = React.useState(0);
  const choosenGov = mohafzat?.find((gov) => gov.text === mohafza);
  const cartItems = useSelector((state) => state.carts.carts?.cart);
  const cartItemsSources = cartItems?.map((item) => item?.item?.main?.code?.substring(0, 2));
  const cartIncludesShop = cartItemsSources?.includes("e0");

  console.log("CART ITEMS", cartIncludesShop);

  const shippingData = {
    near,
    mohafza,
    city,
    district,
    street,
    building,
    home,
    startTime,
    endTime,
    floor,
    branchId,
    shippingWay,
    shippingFees,
    saveAddress,
  };

  React.useEffect(() => {
    if (shippingWay === "1") {
      if (
        building === "" ||
        home === "" ||
        floor === "" ||
        street === "" ||
        city === "" ||
        mohafza === "" ||
        district === "" ||
        mohafza === "" ||
        city === ""
      ) {
        setStop(true);
      } else {
        setStop(false);
        setStopBranch(false);
      }
    } else if (branchId === "" && shippingWay === "2") {
      setStopBranch(true);
    } else {
      setStop(false);
      setStopBranch(false);
    }
  }, [building, district, home, floor, street, city, mohafza, setStop, branchId, shippingWay, setStopBranch]);

  React.useEffect(() => {
    setShippingData(shippingData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingData]);

  React.useEffect(() => {
    if (shippingWay === "0") {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [shippingWay, setDisable]);

  React.useEffect(() => {
    dispatch(getAreas(1));
  }, [choosenGov, dispatch]);

  // React.useEffect(() => {
  //     setCity(theAddress?.city)
  //     setBuilding(theAddress?.home_number)
  //     setDistrict(theAddress?.area)
  //     setStreet(theAddress?.street)
  //     setStartTime(theAddress?.from)
  //     setEndTime(theAddress?.to)
  //     setFloor(theAddress?.floor)
  //     setMohafza(theAddress?.gov)
  //     setHome(theAddress?.apartment)
  //     setNear(theAddress?.near)
  // }, [ theAddress ])

  const currentMethod = methods?.find((method) => parseInt(shippingWay) === method.id);

  React.useEffect(() => {
    setShippingFees(currentMethod ? currentMethod?.price : null);
    setBranchId("");
  }, [currentMethod]);

  // const myMohafza = mohafzat?.filter(row => row.id === parseInt(theAddress.gov))
  // const mohafzaName = myMohafza? myMohafza[0]?.text : null

  // const myCity = cities?.filter(row => row.id === parseInt(theAddress.city))
  // const cityName = myCity? myCity[0]?.text : null

  // React.useEffect(() => {
  //     setCity(cityName)
  //     setMohafza(mohafzaName)
  // }, [cityName, mohafzaName])

  // React.useEffect(() => {
  //     if(typeof parseInt(mohafza) !== 'number') {
  //         setMohafza(theAddress?.gov)
  //         setCity(theAddress?.city)
  //     }
  // }, [theAddress, mohafza])

  // console.log(mohafzat, mohafza , "CITYY")

  const handleSaveChange = (e) => {
    if (e.target.checked) {
      setSaveAddress(1);
    } else {
      setSaveAddress(0);
    }
  };

  React.useEffect(() => {
    dispatch(getAreas(mohafza));
  }, [mohafza, dispatch]);

  React.useEffect(() => {
    if (currentMethod?.has_shipping === 1) {
      setBranchId(branches ? branches[0]?.id : null);
    } else {
      setBranchId("");
    }
  }, [currentMethod]);

  React.useEffect(() => {
    setRes(reservation);
    setResId(reservationId);
  }, [reservation, reservationId]);

  return (
    <div>
      {!cartIncludesShop && (reservations?.length !== 0) & (user?.establishment !== "e14") && (
        <h4 className="text-center mt-3 mb-3 bold" style={{ color: "red" }}>
          برجاء إختيار الدفع مقدمًا للتعرف على طرق الحجز المتاحة
        </h4>
      )}
      <label>اختر طريقة استلام الطلب</label>
      <select defaultValue={0} onChange={(e) => setShippingWay(e.target.value)} className="form-select mb-4">
        <option value={0}>اختر</option>
        {methods?.map((method) => {
          return (
            <option key={method.d} value={method.id}>
              {method.name}
            </option>
          );
        })}
      </select>
      {shippingWay === "1" ? (
        <form>
          <div className="row">
            <div className="col-md-4">
              <label>
                المحافظة <RedStar />{" "}
              </label>
              {
                <select
                  defaultValue={theAddress?.gov}
                  onChange={(e) => setMohafza(e.target.value)}
                  className="form-select app-input"
                >
                  <option value="">اختر محافظة</option>
                  {mohafzat?.map((row, index) => {
                    return (
                      <option key={index} value={row.text}>
                        {row.text}
                      </option>
                    );
                  })}
                </select>
              }
            </div>
            <div className="col-md-4">
              <label>
                المدينة <RedStar />{" "}
              </label>
              {theAddress?.city === "" ? (
                <BiLoader className="login-loader" />
              ) : (
                <select
                  defaultValue={theAddress?.city}
                  onChange={(e) => setCity(e.target.value)}
                  className="form-select app-input"
                >
                  <option value="">اختر مدينة</option>
                  {cities?.map((row, index) => {
                    return (
                      <option key={index} value={row.text}>
                        {row.text}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>
            <div className="col-md-4">
              <label>المنطقة</label>
              <input
                required
                defaultValue={theAddress?.area}
                onChange={(e) => setDistrict(e.target.value)}
                className="form-control app-input"
                placeholder="المنطقة"
              />
            </div>
            <div className="col-md-4">
              <label>
                الشارع <RedStar />{" "}
              </label>
              <input
                required
                defaultValue={theAddress?.street}
                onChange={(e) => setStreet(e.target.value)}
                className="form-control app-input"
                placeholder="الشارع"
              />
            </div>
            <div className="col-md-4">
              <label>
                رقم المنزل <RedStar />{" "}
              </label>
              <input
                required
                defaultValue={theAddress?.home_number}
                onChange={(e) => setBuilding(e.target.value)}
                className="form-control app-input"
                placeholder="رقم المنزل"
              />
            </div>
            <div className="col-md-4">
              <label>
                الطابق <RedStar />{" "}
              </label>
              <input
                required
                defaultValue={theAddress?.floor}
                onChange={(e) => setFloor(e.target.value)}
                className="form-control app-input"
                placeholder="الطابق"
              />
            </div>
            <div className="col-md-4">
              <label>
                الشقه <RedStar />{" "}
              </label>
              <input
                required
                defaultValue={theAddress?.apartment}
                onChange={(e) => setHome(e.target.value)}
                className="form-control app-input"
                placeholder="الشقه"
              />
            </div>
            <div className="col-md-4">
              <label>قريباً من</label>
              <input
                required
                defaultValue={theAddress?.near}
                onChange={(e) => setNear(e.target.value)}
                className="form-control app-input"
                placeholder="قريب من"
              />
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-6">
                  <label>موعد التسليم المفضل</label>
                  <input
                    required
                    defaultValue={theAddress?.from}
                    type="time"
                    onChange={(e) => setStartTime(e.target.value)}
                    className="form-control app-input"
                    placeholder="الشقه"
                  />
                </div>
                <div className="col-md-6">
                  <label></label>
                  <input
                    required
                    defaultValue={theAddress?.to}
                    type="time"
                    onChange={(e) => setEndTime(e.target.value)}
                    className="form-control app-input mt-1"
                    placeholder="الشقه"
                  />
                </div>
              </div>
            </div>
            <div className="checkbox-wrapper mb-2">
              <input onChange={handleSaveChange} className="app-checkbox" type="checkbox" />
              <label>حفظ العنوان </label>
            </div>
          </div>
        </form>
      ) : null}
      {currentMethod?.has_shipping === 1 ? (
        <div>
          {/* <h4>اختر الفرع المناسب</h4> */}
          {branches?.map((branch) => {
            return (
              <div key={branch.id} className="flex-radio">
                <input
                  defaultChecked={true}
                  className="checkout-radio"
                  type="radio"
                  name="formHorizontalRadios"
                  id="formHorizontalRadios1"
                />
                <label>{branch.name}</label>
              </div>
            );
          })}
        </div>
      ) : null}
      {currentMethod ? <div className="p-4 mb-2 text-center note-wrapper">{currentMethod?.note}</div> : null}
      <hr />
      {!cartIncludesShop && (reservations?.length !== 0) & (user?.establishment !== "e14") ? (
        <div>
          <h3 className="text-center mt-3 -mb-3 bold" style={{ color: "red" }}>
            لن يتم تسليم الطلبات التي سيتم دفع جزء من قيمتها إلا بعد سداد المبلغ المتبقي
          </h3>
          <input onChange={(e) => setReservation(e.target.checked)} type="checkbox" style={{ marginLeft: "5px" }} />
          <label>الدفع مقدماً</label>
          <br />
          <label>طريقة الحجز</label>
          <select
            onChange={(e) => setReservationId(e.target.value)}
            disabled={!reservation}
            className="form-select mb-4"
          >
            <option value={0}>اختر طريقة الحجز</option>
            {reservations?.map((item, index) => {
              return (
                <option value={item.id} key={index}>
                  {item?.description}
                </option>
              );
            })}
          </select>
        </div>
      ) : null}
    </div>
  );
};

export default SecondStep;
