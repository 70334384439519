import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser'
import { setLoading } from '../redux/actions/loading';
import { getTerms } from '../redux/actions/termsActions';
import AppSpinner from '../components/Spinner/Spinner';

const AboutUs = () => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(setLoading())
        dispatch(getTerms())
    }, [dispatch])
    const terms = useSelector(state => state.terms.terms?.setting?.about)

    return (
        terms ?
            <div className='container'>
                <h2 className='text-center bold mb-2 mt-3'>عن التطبيق</h2>
                {
                    terms?.length > 3 ?
                        parse(terms) : null
                }
            </div> : <AppSpinner />
    )
}

export default AboutUs;