import React from "react";
import ProductCard from "../components/ProductsCard";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { AiOutlineShoppingCart, AiOutlineHeart, AiOutlineShareAlt, AiFillHeart } from "react-icons/ai";
import { GiMoneyStack } from "react-icons/gi";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { getOffers, getProductById, getSizes } from "../redux/actions/productsActions";
import FullScreenModal from "../components/FullScreenModal";
// import { setLoading } from '../redux/actions/loading';
import AppSpinner from "../components/Spinner/Spinner";
import { addToCart, addToContinue, wishListAddOrRemove } from "../redux/actions/cartsActions";
import { myError, myToast } from "../components/Toast";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ViewProduct = () => {
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();
  const url = window.location.href;
  const { productId } = useParams();
  const [show, setShow] = React.useState(false);
  const dispatch = useDispatch();
  const product = useSelector((state) => state.products?.product?.product);
  const expireDate = new Date(product?.expired_at);
  const isExpired = Date.now() - expireDate > 0 ? true : false;
  const products = useSelector((state) => state.products.offers?.products);
  const sizes = useSelector((state) => state.products.sizes?.sizes);
  const [color, setColor] = React.useState("");
  const [size, setSize] = React.useState("");
  const [price, setPrice] = React.useState();
  const [quantity, setQuantity] = React.useState(1);
  const turnBack = useSelector((state) => state.carts.return);
  const moveToCart = useSelector((state) => state.carts.continue);
  const [isFav, setIsFav] = React.useState(product?.is_favorite);
  const user = useSelector((state) => state.users.user);

  console.log(product?.size_img_original_path, "SIZE URL");
  React.useEffect(() => {
    dispatch(getProductById(productId, setLoading));
    dispatch(getOffers());
    setSize("");
    setColor("");
  }, [productId, dispatch]);

  React.useEffect(() => {
    setLoading(true);
  }, [productId]);

  console.log("IS EXPIRED FROM PRODUCT", isExpired);
  React.useEffect(() => {
    setIsFav(product?.is_favorite);
  }, [product]);

  // console.log('EXXXXXXXPpp', isExpired)

  React.useState(() => {
    document.title = "Blue Point | عرض المنتج";
  }, []);

  const arrayOfColors = product?.items?.map((row) => row.color);

  const uniqueChars = arrayOfColors?.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);

  // console.log(uniqueChars);

  React.useEffect(() => {
    setQuantity(1);
  }, [size]);

  React.useEffect(() => {
    dispatch(getSizes(productId, color));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color, dispatch, productId]);

  React.useEffect(() => {
    setColor(product?.items ? product?.items[0]?.color_id : null);
  }, [product]);

  React.useEffect(() => {
    setPrice(parseInt(size?.price));
  }, [size]);

  React.useEffect(() => {
    if (turnBack) {
      history.push("/");
    }
  }, [turnBack, history]);

  React.useEffect(() => {
    if (moveToCart) {
      history.push("/cart");
    }
  }, [moveToCart, history]);

  // console.log(color)

  const modalBody = (
    <div className="container row">
      <div className="col-sm-6">
        <img alt="img" className="size-table" src={product?.size_img_original_path} />
        <p>{product?.size_desc}</p>
        {product?.size_url?.length > 12 && <iframe title="product" className="product-video" src={product?.size_url} />}
      </div>
      <div className="col-sm-6 flex-center">
        <img alt="img" className="size-simulator" src={product?.size_img_2_original_path} />
      </div>
    </div>
  );

  const addToTheCart = () => {
    if (size === "") {
      myError("برجاء اختيار المقاس اولاً");
    } else if (size?.quantity === 0 && isExpired) {
      myError("عفواً, المقاس غير متاح حالياً");
      // } else if (user?.establishment === "e14") {
      //   if (user?.branch_id === 1) {
      //     if (size?.first_branch == 0) {
      //       myError(`المنتج غيير متوفر`);
      //     }
      //   } else if (user?.branch_id === 2) {
      //     if (size?.second_branch == 0) {
      //       myError(`المنتج غيير متوفر`);
      //     }
      //   } else if (user?.branch_id === 3) {
      //     if (size?.third_branch == 0) {
      //       myError(`المنتج غيير متوفر`);
      //     }
      //   }
    } else {
      dispatch(addToCart(quantity, size.id));
    }
  };

  const addAndContinue = () => {
    if (size === "") {
      myError("برجاء اختيار المقاس اولاً");
    } else if (size?.quantity === 0 && isExpired) {
      myError("عفواً, المقاس غير متاح حالياً");
      // } else if (user?.establishment === "e14") {
      //   if (user?.branch_id === 1) {
      //     if (size?.first_branch == 0) {
      //       myError(`المنتج غيير متوفر`);
      //     }
      //   } else if (user?.branch_id === 2) {
      //     if (size?.second_branch == 0) {
      //       myError(`المنتج غيير متوفر`);
      //     }
      //   } else if (user?.branch_id === 3) {
      //     if (size?.third_branch == 0) {
      //       myError(`المنتج غيير متوفر`);
      //     }
      //   }
    } else {
      dispatch(addToContinue(quantity, size.id));
    }
  };

  const handleFav = () => {
    dispatch(wishListAddOrRemove(product?.id));
    setIsFav(!isFav);
  };
  // console.log(quantity)

  const handlePlus = () => {
    if (user?.establishment === "e14") {
      setQuantity(parseInt(quantity) + 1);
    } else if (user?.establishment !== "e14") {
      if (isExpired) {
        if (quantity === size?.quantity) {
          myError(`العدد المتوفر من المنتج ${quantity} فقط`);
        } else {
          setQuantity(parseInt(quantity) + 1);
        }
      } else {
        setQuantity(parseInt(quantity) + 1);
      }
    }
  };

  return loading ? (
    <div className="m-5 p-5">
      <AppSpinner />
    </div>
  ) : product ? (
    <div className="container">
      <FullScreenModal modalTitle="قياس المنتج" body={modalBody} show={show} setShow={setShow} />

      <div className="row mt-4">
        <div className="col-md-4 carousel-direction">
          <Carousel>
            {product?.photos?.map((photo) => {
              return (
                <div>
                  <img
                    style={{ objectFit: "cover" }}
                    className="carousel-image"
                    key={photo.id}
                    alt="carousel"
                    src={photo?.photo_original_path}
                  />
                </div>
              );
            })}
          </Carousel>
        </div>

        <div className="col-md-1"></div>

        <div className="col-md-6">
          <div className="flex-between">
            <h2 className="bold">{product?.title}</h2>
            {price < size?.old_price ? (
              <span className="discount-card">خصم {(100 - (price / size?.old_price) * 100).toFixed(0)}%</span>
            ) : null}
          </div>
          <hr />
          <span>{product?.description}</span>
          <hr />
          <button className="login-button" onClick={() => setShow(true)}>
            كيفية القياس
          </button>
          <hr />
          <div className="color-wrapper choose-color">
            <span className="color-title">اللون: </span>
            <div className="flex-between">
              <div className="radio-toolbar">
                {uniqueChars?.map((row, index) => {
                  return (
                    <>
                      <input
                        defaultChecked={index === 0 ? true : false}
                        onClick={() => setColor(row.id)}
                        type="radio"
                        id={`color${index}`}
                        name="radioFruit"
                      />
                      <label for={`color${index}`}>{row?.name}</label>
                    </>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="color-wrapper choose-color mt-4">
            <span className="color-title">المقاس: </span>
            <div className="flex-between">
              {sizes?.map((row, index) => {
                console.log("ROW", row.quantity === 0 && isExpired);
                return (
                  <>
                    <input
                      onClick={() => setSize(row)}
                      type="radio"
                      id={`size${index}`}
                      name="sizes"
                      disabled={row?.status === "disabled" || (row.quantity === 0 && isExpired) ? true : false}
                    />
                    <div style={{ position: "relative", display: "inline" }}>
                      <label
                        style={{ background: row === size ? "#999" : "none" }}
                        title={row?.status === "disabled" ? "المقاس غير متاح" : null}
                        className={
                          row?.status === "disabled" || (row.quantity === 0 && isExpired) ? "disabled-size" : ""
                        }
                        for={`size${index}`}
                      >
                        {row?.size.name}
                      </label>
                      <div
                        className={
                          row?.status === "disabled" || (row.quantity === 0 && isExpired) ? "disabled-red" : ""
                        }
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <div className="color-wrapper mt-4 mb-3">
            <span className="color-title">الكمية: </span>
            <div className="quantity-counter">
              <span onClick={() => (quantity !== 1 ? setQuantity(parseInt(quantity) - 1) : null)} className="minus">
                {" "}
                -{" "}
              </span>
              {user?.establishment === "e14" ? (
                <input type="number" value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value))} />
              ) : isExpired ? (
                <span>{quantity}</span>
              ) : (
                <input type="number" value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value))} />
              )}
              <span onClick={() => handlePlus()} className="plus">
                {" "}
                +{" "}
              </span>
            </div>
          </div>

          <div className="color-wrapper mt-4 mb-3">
            <span className="color-title">السعر: </span>
            {price > 1 ? (
              <span>
                {price} جنيه{" "}
                {price < size?.old_price ? <span className="old-price-big">{size?.old_price} جنيه</span> : null}
              </span>
            ) : (
              <span>السعر حسب المقاس</span>
            )}
          </div>

          <hr />

          <div>
            <div className="row flex-center">
              <div className="col-sm-3 flex-center">
                {price > 0 ? (
                  <h3>
                    {price * quantity} جنيه <br />{" "}
                    {price < size?.old_price ? (
                      <span className="old-price-big">{size?.old_price * quantity} جنيه</span>
                    ) : null}
                  </h3>
                ) : (
                  <span className="price-text">السعر حسب المقاس</span>
                )}
              </div>

              <div className="col-sm-7 flex-between">
                <button onClick={addToTheCart} className="login-button add-to-cart-butt">
                  <AiOutlineShoppingCart /> إضافة إلى السلة
                </button>
                <button onClick={addAndContinue} className="login-button add-to-cart-butt buy-butt">
                  <GiMoneyStack /> شــــــراء الان
                </button>
              </div>

              <div className="col-sm-2 flex-between">
                {isFav ? (
                  <AiFillHeart onClick={handleFav} className="add-to-fav-butt" />
                ) : (
                  <AiOutlineHeart onClick={handleFav} className="add-to-fav-butt" />
                )}
                <CopyToClipboard text={url}>
                  <AiOutlineShareAlt onClick={() => myToast("تم نسخ رابط المنتج")} className="add-to-fav-butt" />
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Related Products */}

      <hr />

      <h2 className="bold">
        عروض المتجر <span style={{ color: "#1d66aa" }}>(العروض على منتجات ليست من مفردات الزي المدرسي)</span>
      </h2>

      <div className="row container mt-4">
        {products?.map((product) => {
          return (
            <div key={product.id} className="col-lg-4 col-md-6 col-sm-12 flex-center">
              <ProductCard
                code={product?.code}
                fav={product.is_favorite}
                id={product.id}
                url={product.main_photo_original_path}
                title={product.title}
              />
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <AppSpinner />
  );
};

export default ViewProduct;
