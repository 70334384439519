import React from 'react';
import { Modal } from 'react-bootstrap';

function AppModal({show, setShow, content, modalTitle}) {
    

    const handleClose = () => setShow(false);

    return (
        <>

            <Modal show={show} onHide={handleClose}>
                <div className='flex-between modal-header'>
                    <p className='modal-header-items'>{modalTitle}</p>
                    <p onClick={() => setShow(false)} className='pointer modal-header-items times'>×</p>
                </div>


                <p className='modal-body'>{content}</p>
                
            </Modal>
        </>
    );
}

export default AppModal;