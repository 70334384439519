import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import FullScreenModal from "./FullScreenModal";
import AppModal from "./Modal";
import ProductsForExchange from "./ProductsForExchange";
import ReturnProducts from "./ReturnProducts";
import { myError, myToast } from "./Toast";

const ExchangeProducts = ({ ExchangeButton, getOrder, products, orderId, setShowDown, paymentId, order }) => {
  const dispatch = useDispatch();
  const [product, setProduct] = React.useState(null);
  const methods = useSelector((state) => state.checkout?.reasons?.methods);
  const [showFull, setShowFull] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [balanceData, setBalanceData] = React.useState();
  const [shippingMethod, setShippingMethod] = React.useState("0");
  const address = useSelector((state) => state.checkout?.reasons?.address);
  const branches = useSelector((state) => state.checkout?.resons?.branches);
  const theAddress = JSON.parse(address ? address : '{"gosv":"", "csity":"", "rcity":""}');
  const [near, setNear] = React.useState(theAddress?.near);
  const [mohafza, setMohafza] = React.useState(theAddress?.gov);
  const [city, setCity] = React.useState(theAddress?.city);
  const [district, setDistrict] = React.useState(theAddress?.area);
  const [street, setStreet] = React.useState(theAddress?.street);
  const [building, setBuilding] = React.useState(theAddress?.home_number);
  const [floor, setFloor] = React.useState(theAddress?.floor);
  const [home, setHome] = React.useState(theAddress?.apartment);
  const [startTime, setStartTime] = React.useState(theAddress?.from);
  const [endTime, setEndTime] = React.useState(theAddress?.to);
  const [branchId, setBranchId] = React.useState("0");
  const [shippingPrice, setShippingPrice] = React.useState("0");
  const [saveAddress, setSaveAddress] = React.useState(0);
  const productsIds = balanceData?.items?.map((item) => item?.from_id);
  const [exchangeMethods, setExchangeMethods] = React.useState([]);

  const getMethods = () => {
    axios
      .get(`https://bluepointwear.com/backend/ar/api/methods`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setExchangeMethods(res.data);
        setShippingPrice(res.data?.price);
      });
  };

  React.useEffect(() => {
    getMethods();
  }, []);

  console.log("EXCHANGE METHODS", exchangeMethods);

  console.log("PRODUcts IDS", productsIds);

  console.log("BALANCE DATA", data);

  React.useEffect(() => {
    setMohafza(theAddress?.gov);
    setCity(theAddress?.city);
  }, [theAddress]);

  // console.log('MOHAFZA', shippingPrice)

  const shippingData = {
    near,
    mohafza,
    city,
    district,
    street,
    building,
    home,
    startTime,
    endTime,
    floor,
    branchId,
    shippingMethod,
    shippingPrice,
    saveAddress,
  };
  // console.log(ids)

  const sendRequest = (formData) => (dispatch) => {
    axios
      .post(
        `https://bluepointwear.com/backend/ar/api/${
          order?.changes[0]?.modify === 1 ? "change-order-update" : "change-order"
        }`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setBalanceData(res.data);
        myToast("تم ارسال طلب الاستبدال");
        setShow(false);
        dispatch(getOrder());
      })
      .catch((err) => console.log(err));
  };

  const currentMethod = methods?.filter((method) => parseInt(shippingMethod) === method.id);

  React.useEffect(() => {
    // setShippingPrice(currentMethod ? currentMethod[0]?.price : null);
  }, [currentMethod]);

  const handleModal = (product) => {
    setShowFull(true);
    setProduct(product);
  };

  const handleSmallModal = (product) => {
    setShow(true);
    setProduct(product);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("order_id", orderId);
    formData.append("shipping_id", 2);
    formData.append("payment_id", 1);
    if (shippingMethod === "1") {
      formData.append("address[gov]", shippingData.mohafza);
      formData.append("address[city]", shippingData.city);
      formData.append("address[near]", shippingData.near);
      formData.append("address[street]", shippingData.street);
      formData.append("address[area]", shippingData.district);
      formData.append("address[home_number]", shippingData.building);
      formData.append("address[apartment]", shippingData.home);
      formData.append("address[floor]", shippingData.floor);
      formData.append("address[from]", shippingData.startTime);
      formData.append("address[to]", shippingData.endTime);
      formData.append("save_address", shippingData.saveAddress);
    }
    if (shippingMethod === "3") {
      formData.append("branch_id", shippingData.branchId);
    }
    data.map((row, index) => formData.append(`items[old_item][${index + 1}][id]`, row.oldItemId));
    data.map((row, index) => formData.append(`items[old_item][${index + 1}][quantity]`, row.oldItemQuantity));
    data.map((row, index) => formData.append(`items[new_item][${index + 1}][id]`, row.newItemId));
    data.map((row, index) => formData.append(`items[new_item][${index + 1}][quantity]`, row.newItemQuantity));
    data.map((row, index) => formData.append(`items[new_item][${index + 1}][reason]`, row.reason));
    if (data?.length === 0) {
      myError("برجاء اختيار المنتجات");
    } else if (shippingMethod === "2" && branchId === "0") {
      myError("برجاء اختيار الفرع");
    } else if (shippingMethod === "1") {
      if (
        building === "" ||
        district === "" ||
        home === "" ||
        floor === "" ||
        street === "" ||
        city === "" ||
        mohafza === ""
      ) {
        myError("برجاء ملئ العنوان كامل");
      } else {
        dispatch(sendRequest(formData));
        setShowDown(false);
      }
    } else {
      dispatch(sendRequest(formData));
      setShowDown(false);
    }
  };

  // console.log(shippingPrice, 'SHHHHHHHHPING')

  // console.log("SHHHHHH", shippingMethod, 'BBRRRRRR', branchId)

  const handleSaveChange = (e) => {
    if (e.target.checked) {
      setSaveAddress(1);
    } else {
      setSaveAddress(0);
    }
  };

  //   React.useEffect(() => {
  //     setShippingMethod(3);
  //   }, []);

  return (
    <div>
      <AppModal
        show={show}
        setShow={setShow}
        content={
          <ReturnProducts
            orderId={orderId}
            product={product}
            setShow={setShow}
            data={data}
            setData={setData}
            setBalanceData={setBalanceData}
          />
        }
        modalTitle="الإسترجاع"
      />
      <FullScreenModal
        body={
          <ProductsForExchange
            setShow={setShowFull}
            orderId={orderId}
            setBalanceData={setBalanceData}
            oldProduct={product}
            data={data}
            setData={setData}
          />
        }
        modalTitle="اختار منتج للاستبدال"
        show={showFull}
        setShow={setShowFull}
      />
      {/* <h3 style={{ color: 'red' }} className='text-center red mb-3 bold'>ملحوظه: الاستبدال يتم مره واحده فقط على الطلب</h3> */}
      <table className="table table-borderless table-hover mt-2 mb-3">
        <thead>
          <tr>
            <th scope="col">المنتج</th>
            <th scope="col">اللون</th>
            <th scope="col">المقاس</th>
            {order?.replace_available === 0 ? null : <th scope="col">استبدال</th>}
            {<th scope="col">استرجاع</th>}
          </tr>
        </thead>
        <tbody>
          {products?.map((product) => {
            return (
              <tr
                style={productsIds?.includes(product?.item_id) ? { background: "rgba(194, 249, 112, 0.8)" } : null}
                key={product.id}
              >
                <td>{product?.product?.title}</td>
                <td>{product?.color?.name}</td>
                <td>{product?.size?.name}</td>
                {order?.replace_available === 0 ? null : (
                  <td>
                    <button
                      onClick={() => handleModal(product)}
                      className="login-button exchange-button exchange-inside"
                    >
                      استبدال
                    </button>
                  </td>
                )}
                {
                  <td>
                    <button
                      onClick={() => handleSmallModal(product)}
                      className="login-button exchange-button exchange-inside"
                    >
                      استرجاع
                    </button>
                  </td>
                }
              </tr>
            );
          })}
        </tbody>
      </table>

      <h4 className="mt-2">برجاء مراجعة مواعيد الأستبدال على صفحة الأخبار</h4>

      {/* <select
        onChange={(e) => setShippingMethod(e.target.value)}
        className="form-select app-select mb-3"
      >
        <option value="">طريقة الشحن</option>
        {methods?.map((method) => {
          return (
            <option value={method.id} key={method.id}>
              {method.name}
            </option>
          );
        })}
      </select> */}
      <h5 className="bold">{exchangeMethods?.name}</h5>
      {shippingMethod === "1" ? (
        <form>
          <div className="row">
            <div className="col-md-4">
              <label>المحافظه</label>
              <input
                required
                defaultValue={mohafza}
                onChange={(e) => setMohafza(e.target.value)}
                className="form-control app-input"
                placeholder="المحافظه"
              />
            </div>
            <div className="col-md-4">
              <label>المدينة</label>
              <input
                required
                defaultValue={city}
                onChange={(e) => setCity(e.target.value)}
                className="form-control app-input"
                placeholder="المدينة"
              />
            </div>
            <div className="col-md-4">
              <label>المنطقة</label>
              <input
                required
                defaultValue={theAddress?.area}
                onChange={(e) => setDistrict(e.target.value)}
                className="form-control app-input"
                placeholder="المنطقة"
              />
            </div>
            <div className="col-md-4">
              <label>الشارع</label>
              <input
                required
                defaultValue={theAddress?.street}
                onChange={(e) => setStreet(e.target.value)}
                className="form-control app-input"
                placeholder="الشارع"
              />
            </div>
            <div className="col-md-4">
              <label>رقم المنزل</label>
              <input
                required
                defaultValue={theAddress?.home_number}
                onChange={(e) => setBuilding(e.target.value)}
                className="form-control app-input"
                placeholder="رقم المنزل"
              />
            </div>
            <div className="col-md-4">
              <label>الطابق</label>
              <input
                required
                defaultValue={theAddress?.floor}
                onChange={(e) => setFloor(e.target.value)}
                className="form-control app-input"
                placeholder="الطابق"
              />
            </div>
            <div className="col-md-4">
              <label>الشقه</label>
              <input
                required
                defaultValue={theAddress?.apartment}
                onChange={(e) => setHome(e.target.value)}
                className="form-control app-input"
                placeholder="الشقه"
              />
            </div>
            <div className="col-md-4">
              <label>قريباً من</label>
              <input
                required
                defaultValue={theAddress?.near}
                onChange={(e) => setNear(e.target.value)}
                className="form-control app-input"
                placeholder="قريب من"
              />
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-6">
                  <label>موعد التسليم المفضل</label>
                  <input
                    required
                    defaultValue={theAddress?.from}
                    type="time"
                    onChange={(e) => setStartTime(e.target.value)}
                    className="form-control app-input"
                    placeholder="الشقه"
                  />
                </div>
                <div className="col-md-6">
                  <label></label>
                  <input
                    required
                    defaultValue={theAddress?.to}
                    type="time"
                    onChange={(e) => setEndTime(e.target.value)}
                    className="form-control app-input mt-1"
                    placeholder="الشقه"
                  />
                </div>
              </div>
            </div>
            <div className="checkbox-wrapper mb-2">
              <input onChange={handleSaveChange} className="app-checkbox" type="checkbox" />
              <label>حفظ العنوان </label>
            </div>
          </div>
        </form>
      ) : null}
      {shippingMethod === "2" ? (
        <div>
          <h4>اختر الفرع المناسب</h4>
          {branches?.map((branch) => {
            return (
              <div key={branch.id} className="flex-radio">
                <input
                  className="checkout-radio"
                  type="radio"
                  name="formHorizontalRadios"
                  id="formHorizontalRadios1"
                  onClick={() => setBranchId(branch.id)}
                />
                <label>{branch.name}</label>
              </div>
            );
          })}
        </div>
      ) : null}
      {balanceData ? (
        <div className="row">
          {typeof shippingPrice === "number" ? (
            <p className="bold mb-2 col-md-6">
              <span className="order-data-header">مصاريف الشحن</span>: {shippingPrice}
            </p>
          ) : null}
          <p className="bold mb-2 col-md-6">
            <span className="order-data-header">قيمة المرتجع</span>: {balanceData?.oldItemAmount}
          </p>
          <p className="bold mb-2 col-md-6">
            <span className="order-data-header">قيمة المنتجات الجديدة</span>: {balanceData?.newItemAmount}
          </p>
          <p className="bold mb-2 col-md-6">
            <span className="order-data-header">الفرق</span>:
            <span style={balanceData.total > 0 ? { color: "green" } : { color: "red" }}>
              {" "}
              {balanceData?.total + shippingPrice}{" "}
            </span>
          </p>
          {/* {typeof shippingPrice === "number" ? (
            <p className="bold mb-2 col-md-6">
              <span className="order-data-header">الإجمالي</span>:{" "}
              {balanceData?.total + shippingPrice}
            </p>
          ) : null} */}
        </div>
      ) : null}
      <div className="flex-between">
        <div />
        <h6 className="bold" style={{ color: "red" }}>
          ملحوظه: عملية الإسبتدال لا تتم إلى بالضغط على هذا الزر ↓
        </h6>
      </div>
      <button onClick={() => handleSubmit()} className="login-button exchange-button w-100">
        برجاء الضغط للحفظ و إنهاء عملية الاستبدال
      </button>
    </div>
  );
};

export default ExchangeProducts;
