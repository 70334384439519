import React from 'react';
import { BiLoaderAlt } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { confirmEmail } from '../redux/actions/usersActions';

const ConfirmEmail = () => {
    const [email, setEmail] = React.useState();
    const [code, setCode] = React.useState();
    const [redirect, setRedirect] = React.useState(false)
    const changeError = useSelector(state => state?.users?.emailConfirmError);
    const history = useHistory();
    const [loading, setLoading] = React.useState(false)
    const dispatch = useDispatch();
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(confirmEmail({ email, verification_code: code }, setRedirect, setLoading))
    }

    React.useEffect(() => {
        if (redirect) {
            history.push('/my-profile')
            window.location.reload();
        }
    }, [redirect, history])



    return (
        <div className='container'>
            <div className='flex-center'>
            </div>
            <h2 className='text-center bold'>حسابي</h2>
            <h4 className='text-center bold mb-4'>تغيير البريد الإلكتروني</h4>
            <div className='row update-info-container'>
                <form onSubmit={handleSubmit}>
                    <div className='row mt-3'>
                        <label>البريد الإلكتروني الجديد</label>
                        <input required type='email' onChange={(e) => setEmail(e.target.value)} placeholder='ادخل البريد الإلكتروني الجديد (برجاء كتابة إيميل صحيح لإستخدامه في متابعة الطلبات)' className='form-control app-input mb-2' />
                        <button className='login-button profile-buttons'>{loading ? <BiLoaderAlt className='login-loader' /> : "تغيير البريد الإلكتروني"}</button>
                        <p className='login-error'>{changeError}</p>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ConfirmEmail;