import {
  ADD_PRODUCTS,
  GET_MAIN_CATEGORY_PRODUCTS,
  GET_OFFERS,
  GET_PRODUCTS,
  GET_PRODUCT_BY_ID,
  GET_SIZES,
  GET_SUB_CATEGORY_PRODUCTS,
  LOADING,
  PRODUCT_LOADING,
} from "../actions/types";

const initialState = {
  products: [],
  mainCategoryProducts: [],
  subCategoryProducts: [],
  product: [],
  updated: [],
  loading: true,
  sizes: [],
  offers: [],
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        loading: false,
      };
    case GET_OFFERS:
      return {
        ...state,
        offers: action.payload,
        loading: false,
      };
    case GET_SIZES:
      return {
        ...state,
        sizes: action.payload,
        loading: false,
      };
    case GET_MAIN_CATEGORY_PRODUCTS:
      return {
        ...state,
        mainCategoryProducts: action.payload,
        loading: false,
      };
    case GET_SUB_CATEGORY_PRODUCTS:
      return {
        ...state,
        subCategoryProducts: action.payload,
        loading: false,
      };
    case GET_PRODUCT_BY_ID:
      return {
        ...state,
        product: action.payload,
        loading: false,
      };
    case ADD_PRODUCTS:
      return {
        ...state,
        updated: Math.random(),
        loading: false,
      };
    case PRODUCT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default productsReducer;
