import axios from 'axios';
import React from 'react';
import { BiLoaderAlt } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getCart } from '../redux/actions/cartsActions';
import CartContent from './CartContent';

const FirstStep = ({ setDisable, setCost, cost, setStopAgree, stopAgree, setStop, setStopBranch, setCode, setCouponText }) => {
    const loading = useSelector(state => state.carts.loading);
    const dispatch = useDispatch();
    const history = useHistory();
    const [totalCost, setTotalCost] = React.useState();
    const [agree, setAgree] = React.useState(true);
    const [rendered, setRendered] = React.useState(false);
    const cartItems = useSelector(state => state.carts.carts?.cart);

    setTimeout(() => {
        setRendered(true)
    }, [200])

    React.useEffect(() => {
        dispatch(getCart())
    }, [])
    React.useEffect(() => {
        if (rendered) {
            setCost(totalCost)
            setDisable(false)
            setStopBranch(false)
            setStop(false)
            setCode(0)
            setCouponText('')
        }
    }, [totalCost, rendered, setCost, setDisable, setStopBranch, setStop, setCode, setCouponText])

    React.useEffect(() => {
        if (rendered) {
            setStopAgree(agree)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agree])

    const getOrders = (currentPage) => dispatch => {
        axios.get(`https://bluepointwear.com/backend/ar/api/orders?page=${1}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => {
                history.push(`/view-order/${res.data?.orders?.data ? res.data?.orders?.data[0]?.id : null}`)
            })
            .catch(err => console.log(err))
    }

    React.useEffect(() => {
        if (cartItems?.length === 0) {
            dispatch(getOrders())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItems, dispatch])



    // console.log(cost, "COOO")
    return (
        <div>
            <CartContent setTotalCost={setTotalCost} />

            <p className='text-center'>جميع الاسعار شاملة ضريبة القيمة المضافة بنسبة 14%.</p>
            <div className='checkbox-wrapper mt-3 flex-center'>
                <input onClick={() => {
                    setAgree(!agree)
                }} className='checkout-checkbox' type='checkbox' />
                <h6 className='text-center'>
                    {" "}عن طريق تقديم طلبك ، فإنك توافق علي
                    <span className='link-in-text bold'> سياسية الخصوصية</span> و
                    <span className='link-in-text bold'> شروط الاستخدام </span> اقرأ
                    <span className='link-in-text bold'> سياسة الإستبدال والإسترجاع</span>.
                </h6>
            </div>


            <div className='flex-center mt-4'>
                <div className='flex-center price-proceed-wrapper'>
                    <h4 className='bold'>المجموع: {loading ? <BiLoaderAlt className='login-loader' /> : totalCost} جنيه</h4>
                </div>
            </div>

        </div>
    )
}

export default FirstStep;