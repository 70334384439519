import { GET_CAT, GET_CATEGORY_BY_ID, ADD_CAT, CAT_LOADING} from '../actions/types'

const initialState = {
    categories: [],
    category: [],
    updated: [],
    loading: true
}

const categoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CAT:
            return {
                ...state,
                categories: action.payload,
                loading: false
            };
        case GET_CATEGORY_BY_ID:
            return {
                ...state,
                category: action.payload?.data?.category,
                loading: false
            };
        case ADD_CAT:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case CAT_LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default categoriesReducer;