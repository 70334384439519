/* eslint-disable no-lone-blocks */
import React from "react";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import FourthStep from "./FourthStep";
import FifthStep from "./FifthStep";
import { useDispatch, useSelector } from "react-redux";
import { getCheckout } from "../redux/actions/checkoutActions";
import { myError, myToast } from "./Toast";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import parse from "html-react-parser";
import { configuration } from "../paymentConf";
import { BiLoader } from "react-icons/bi";

const ProgressBar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const methods = useSelector((state) => state.checkout?.checkout?.methods);
  const [mobile, setMobile] = React.useState(false);
  const [progress, setProgress] = React.useState(1);
  const [progressInner, setProgressInner] = React.useState("progress-inner");
  const [content, setContent] = React.useState(<FirstStep />);
  const [disable, setDisable] = React.useState(true);
  const [cost, setCost] = React.useState(true);
  const [shippingData, setShippingData] = React.useState({});
  const [paymentMethod, setPaymentMethod] = React.useState(0);
  const [code, setCode] = React.useState(0);
  const [codeType, setCodeType] = React.useState(0);
  const [stop, setStop] = React.useState(false);
  const [stopBranch, setStopBranch] = React.useState(false);
  const [stopAgree, setStopAgree] = React.useState(true);
  const [couponText, setCouponText] = React.useState();
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [error, setError] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const [reservation, setReservation] = React.useState(false);
  const [reservationId, setReservationId] = React.useState(0);
  const [descError, setDescError] = React.useState("");
  const user = useSelector((state) => state.users.user);
  console.log("ERR", error?.data?.msg);
  const currentMethod = methods?.find((method) => parseInt(shippingData.shippingWay) === method.id);

  console.log(reservation, reservationId, "RESERVATIOn");

  // console.log("CONFIGUREE", window.Checkout.configure)

  const addOrder = (payment, data, code) => (dispatch) => {
    setLoading(true);
    const homeData = new FormData();
    homeData.append("payment_id", payment);
    homeData.append("shipping_id", data.shippingWay);
    homeData.append("address[gov]", data.mohafza);
    homeData.append("address[city]", data.city);
    homeData.append("address[near]", data.near);
    homeData.append("address[street]", data.street);
    homeData.append("address[area]", data.district);
    homeData.append("address[home_number]", data.building);
    homeData.append("address[apartment]", data.home);
    homeData.append("address[floor]", data.floor);
    homeData.append("address[from]", data.startTime);
    homeData.append("address[to]", data.endTime);
    homeData.append("save_address", data.saveAddress);
    homeData.append("coupon_code", couponText);
    homeData.append("branch_id", data.branchId);
    homeData.append("front_total_price", totalPrice);
    homeData.append("reservation", reservation ? "yes" : null);
    reservation && homeData.append("reservation_id", reservationId);

    if (user?.establishment === "e14") {
      homeData.append("description", description);
    }
    const companyData = new FormData();
    companyData.append("branch_id", data.branchId);
    companyData.append("payment_id", payment);
    companyData.append("shipping_id", data.shippingWay);
    companyData.append("coupon_code", couponText);
    companyData.append("front_total_price", totalPrice);
    companyData.append("reservation", reservation ? "yes" : null);
    companyData.append("reservation_id", reservationId);
    companyData.append("reservation", reservation ? "yes" : null);
    reservation && companyData.append("reservation_id", reservationId);
    if (user?.establishment === "e14") {
      companyData.append("description", description);
    }
    const schoolData = new FormData();
    schoolData.append("branch_id", data.branchId);
    schoolData.append("payment_id", payment);
    schoolData.append("shipping_id", data.shippingWay);
    schoolData.append("coupon_code", couponText);
    schoolData.append("front_total_price", totalPrice);
    schoolData.append("reservation", reservation ? "yes" : null);
    reservation && schoolData.append("reservation_id", reservationId);
    if (user?.establishment === "e14") {
      schoolData.append("description", description);
    }
    axios
      .post(
        `https://bluepointwear.com/backend/ar/api/do-payment`,
        data.shippingWay === "1" ? homeData : data.shippingWay === "2" ? companyData : schoolData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setDescError("");
        if (payment === 2 || payment === "2") {
          // console.log("RESSSSS", res.data)
          let data = res?.data?.data;
          let price = res?.data?.total_price;
          let desc = res?.data?.desc;
          let status = "1";
          let session_id = res?.data?.session_id;
          let transactionid = res?.data?.transactionid;
          let merchent = res?.data?.bank_merchant_name;

          if (desc.length > 120) {
            desc = desc.substring(0, 120);
          }

          if (status) {
            configuration(session_id, price, desc, transactionid, merchent);
          } else {
            console.log(data);
          }
        } else {
          history.push("/");
          myToast(" لقد تم طلبك بنجاح برجاء متابعة الطلب في طلباتي");
        }
      })
      .catch((err) => {
        setError(err.response);
        myError(err?.response?.data?.msg);
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    dispatch(getCheckout());
  }, [dispatch]);

  React.useEffect(() => {
    if (window.innerWidth < 995) {
      setMobile(true);
    }
  }, []);

  console.log("STOP AGREEE", stopAgree);
  React.useEffect(() => {
    if (progress > 0) {
      setProgressInner("20%");
    }
    if (progress > 1) {
      setProgressInner("40%");
    }
    if (progress > 2) {
      setProgressInner("60%");
    }
    if (progress > 3) {
      setProgressInner("80%");
    }
    if (progress > 4) {
      setProgressInner("100%");
    }
    if (progress === 1) {
      setContent(
        <FirstStep
          setCode={setCode}
          setCouponText={setCouponText}
          setStop={setStop}
          setStopBranch={setStopBranch}
          setStopAgree={setStopAgree}
          stopAgree={stopAgree}
          setCost={setCost}
          disable={disable}
          setDisable={setDisable}
        />
      );
    }
    if (progress === 2) {
      setContent(
        <SecondStep
          setStopBranch={setStopBranch}
          setStop={setStop}
          setShippingData={setShippingData}
          disable={disable}
          setDisable={setDisable}
          setRes={setReservation}
          setResId={setReservationId}
        />
      );
    }
    if (progress === 3) {
      setContent(
        <ThirdStep
          couponText={couponText}
          setCouponText={setCouponText}
          setTheCode={setCode}
          setCodeType={setCodeType}
          price={cost}
        />
      );
    }
    if (progress === 4) {
      setContent(
        <FourthStep
          shippingFees={shippingData?.shippingFees}
          totalCost={cost}
          code={code}
          setTotalPrice={setTotalPrice}
          codeType={codeType}
          reservation={reservation}
          reservationId={reservationId}
        />
      );
    }
    if (progress === 5) {
      setContent(
        <FifthStep
          user={user}
          setDescription={setDescription}
          shippingData={shippingData}
          setPaymentMethod={setPaymentMethod}
          totalPrice={totalPrice}
          error={error?.data?.msg}
          reservation={reservation}
          descError={descError}
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  console.log("DESC ERROR", descError);
  const handleNextClick = () => {
    if (stop) {
      myError("برجاء ادخال جميع بيانات العنوان");
    } else if (stopBranch) {
      myError("برجاء اختيار الفرع اولاً");
    } else if (stopAgree) {
      myError("برجاء الموافقة على الشروط والاحكام اولاً");
    } else if (shippingData?.branchId === "" && currentMethod?.has_discount === 1) {
      myError("برجاء اختيار الفرع اولاً");
    } else if (reservation && reservationId == 0) {
      myError("برجاء اختيار طريقة الحجز اولاً");
    } else {
      setProgress(progress + 1);
    }
  };

  const handleSubmit = () => {
    if (paymentMethod === 0 || paymentMethod === "0") {
      myError("برجاء اختيار طريقة الدفع اولاً");
    } else if (user?.establishment === "e14" && description === "") {
      myError("برجاء إدخال الوصف");
      setDescError("برجاء إدخال الوصف");
    } else {
      myToast("برجاء الانتظار");
      dispatch(addOrder(paymentMethod, shippingData, code));
    }
  };

  console.log("PAYMENT METHOD", paymentMethod);

  // console.log("TEXT OUTSIDE", couponText)
  return (
    <div className="container" style={{ marginTop: "50px" }}>
      {mobile ? (
        <div className="progress-step-numbers mb-3">
          <div className={progress > 0 ? "clear" : "blur"}>
            <h4 className="bold mb-0">اولاً</h4>
            <span>المراجعة</span>
          </div>
          <div className={progress > 1 ? "clear" : "blur"}>
            <h4 className="bold mb-0">ثانياً</h4>
            <span>الاستلام</span>
          </div>
          <div className={progress > 2 ? "clear" : "blur"}>
            <h4 className="bold mb-0">ثالثاً</h4>
            <span>الكوبون</span>
          </div>
          <div className={progress > 3 ? "clear" : "blur"}>
            <h4 className="bold mb-0">رابعاً</h4>
            <span>الإجمالي</span>
          </div>
          <div className={progress > 4 ? "clear" : "blur"}>
            <h4 className="bold mb-0">خامساً</h4>
            <span>الدفع</span>
          </div>
        </div>
      ) : (
        <div className="progress-step-numbers mb-3">
          <div className={progress > 0 ? "clear" : "blur"}>
            <h4 className="bold mb-0">الخطوه الاولى</h4>
            <span>مراجعة الطلب</span>
          </div>
          <div className={progress > 1 ? "clear" : "blur"}>
            <h4 className="bold mb-0">الخطوه الثانية</h4>
            <span>طريقة الاستلام</span>
          </div>
          <div className={progress > 2 ? "clear" : "blur"}>
            <h4 className="bold mb-0">الخطوه الثالثة</h4>
            <span>الكوبون</span>
          </div>
          <div className={progress > 3 ? "clear" : "blur"}>
            <h4 className="bold mb-0">الخطوه الرابعه</h4>
            <span>الحساب الإجمالي</span>
          </div>
          <div className={progress > 4 ? "clear" : "blur"}>
            <h4 className="bold mb-0">الخطوه الخامسة</h4>
            <span>طريقة الدفع</span>
          </div>
        </div>
      )}
      <div className="progress-bar mb-2">
        <div className="progress-inner" style={{ width: progressInner, transition: "ease 0.8s" }}></div>
      </div>

      {content}
      <div className="flex-between mb-4">
        <button
          disabled={progress === 1 ? true : false}
          className="login-button radius4"
          onClick={() => setProgress(progress - 1)}
        >
          السابق
        </button>
        {progress !== 5 ? (
          <button disabled={disable} className="login-button radius4" onClick={handleNextClick}>
            التالي
          </button>
        ) : (
          <button
            disabled={loading}
            onClick={() => handleSubmit()}
            className="login-button radius4 buy-button"
            style={{ width: "80px" }}
          >
            {loading ? <BiLoader className="login-loader" /> : "شراء"}
          </button>
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
