import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppSpinner from '../components/Spinner/Spinner';
import { setLoading } from '../redux/actions/loading';
import { getSupport } from '../redux/actions/termsActions';
import AppModal from '../components/Modal';

const Support = () => {
    const dispatch = useDispatch();
    const [show, setShow] = React.useState(false);
    const [answer, setAnswer] = React.useState('')
    const [view, setView] = React.useState('');
    const [ques, setQues] = React.useState('');
    React.useEffect(() => {
        dispatch(setLoading())
        dispatch(getSupport())
    }, [dispatch])
    React.useState(() => {
        document.title = 'Blue Point | الاسئلة الشائعة'
    }, [])
    const supports = useSelector(state => state.terms.support?.supports)
    const loading = useSelector(state => state.terms.loading)
    const handleModal = (ques) => {
        setAnswer(ques.answer)
        setQues(ques.question)
        setShow(true)
    }
    return (
            supports?
            <div className='container'>
                <h2 className='bold text-center mb-3'>الاسئلة الشائعة</h2>
                <AppModal show={show} setShow={setShow} modalTitle={ques} content={answer} />
                {
                    loading ? <AppSpinner /> :
                        supports?.map((row, index) => {
                            return (
                                <div>
                                    <div key={index} onClick={() => setView(view === index ? false : index)} className='support-widget'>{row.name} ᐁ</div>
                                    <div className={view === index ? 'show-content' : 'hide-content'} >
                                        {
                                            row.faqs?.map((ques, index) => {
                                                return (
                                                    <h6 onClick={() => handleModal(ques)} key={index} className='text-center mb-3 bold pointer'>
                                                        {ques?.question}
                                                    </h6>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                }
            </div> : <AppSpinner />
    )
}

export default Support;