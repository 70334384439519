import axios from 'axios';
import { myToast, myError } from '../../components/Toast';
import * as types from './types';
const lang = localStorage.getItem('i18nextLng');


export const getSupport = () => dispatch => {
     axios.get(`https://bluepointwear.com/backend/ar/api/support`, {
          headers: {
               'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
     })
          .then(res => {
               dispatch({
                    type: types.SUPPORT_LOADING,
                    payload: res.data
               })
               dispatch({
                    type: types.GET_SUPPORT,
                    payload: res.data
               })
          })
          .catch(error => myError(error?.response?.message))
}

export const getTerms = () => dispatch => {
     axios.get(`https://bluepointwear.com/backend/ar/api/setting`, {
          headers: {
               'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
     })
          .then(res => {
               dispatch({
                    type: types.TERMS_LOADING,
                    payload: res.data
               })
               dispatch({
                    type: types.GET_TERMS,
                    payload: res.data
               })
          })
}

export const getTermById = (id) => dispatch => {
     axios.get(`https://matjrna-ecommerce.herokuapp.com/api/v1/term/${id}`)
          .then(res => {
               dispatch({
                    type: types.GET_TERM_BY_ID,
                    payload: res.data
               })
          })
          .catch(error => myError(error?.response?.message))
}

export const addTerm = (inputsData) => dispatch => {
     axios.post(`https://matjrna-ecommerce.herokuapp.com/api/v1/term`, inputsData)
          .then(res => {
               dispatch({
                    type: types.ADD_TERM,
                    payload: res
               })
               myToast(lang === 'ar' ? 'تم إضافة شرط جديد' : 'New term has been added')
          })
          .catch(error => myError(error?.response?.message))
}

export const editTerm = (id, inputsData) => dispatch => {
     axios.patch(`https://matjrna-ecommerce.herokuapp.com/api/v1/term/${id}`, inputsData)
          .then(res => {
               dispatch({
                    type: types.ADD_TERM,
                    payload: res
               })
               myToast(lang === 'ar' ? 'تم تعديل الشرط' : 'Term has been edited')
          })
          .catch(error => myError(error?.response?.message))
}

export const deleteTerm = (id) => dispatch => {
     axios.delete(`https://matjrna-ecommerce.herokuapp.com/api/v1/term/${id}`)
          .then(res => {
               dispatch({
                    type: types.ADD_TERM,
                    payload: res
               })
               myToast(lang === 'ar' ? 'تم حذف الشرط' : 'Term has been deleted')
          })
          .catch(error => myError(error?.response?.message))
}