import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import store from './redux/store';
import './index.css';
import Auth from './Auth';
import FullScreenModal from './components/FullScreenModal';

function App() {
  return (
    <Provider store={store}>
      <Auth />
      <FullScreenModal />
    </Provider>
  );
}

export default App;
