import { LOADING,  WISHLIST, WISHLIST_LOADING, WISHLIST_DELETE } from '../actions/types'

const initialState = {
    wishlist: [],
    loading: true,
}

const wishlistReducer = (state = initialState, action) => {
    switch (action.type) {
    
        case WISHLIST:
            return {
                ...state,
                wishlist: action.payload?.products,
                loading: false,
            };


        case WISHLIST_DELETE:
            return {
                ...state,
                updated: Math.random(),
                loading: false
            };
        case WISHLIST_LOADING:
            return {
                loading: true
            };
        case LOADING:
            return {
                loading: true
            };
        default:
            return state
    }

}

export default wishlistReducer;